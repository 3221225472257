/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  Card,
  DialogActions,
  Button,
  useMediaQuery,
  Box,
  Typography,
  Hidden,
  Tooltip,
  Fade,
} from "@mui/material"
import {
  CancelOutlined as CancelOutlinedIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  Videocam as VideocamIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  SummarizeOutlined as SummarizeOutlinedIcon,
} from "@mui/icons-material/"
import { makeStyles } from "@mui/styles"
import { useDispatch, connect } from "react-redux"
import { postMessage, showAlert, fetchAvailableTimeSlots } from "redux/actions"
import { postSharedMaterialToKaddioContact } from "api/postSharedMaterialToKaddioContact"
import { invitePatientToIkbt } from "api/invitePatientToIkbt"
import { getKaddioFormLink } from "api/getKaddioFormLink"
import { bookVideoMeetingByCaregiver } from "api/bookVideoMeetingByCaregiver"
import { useTheme } from "@emotion/react"
import MaterialPicker from "components/MaterialPicker"
import { dataType } from "utils/dataType"
import { trackEvent } from "utils/trackEvent"
import { getUserAge } from "utils/getUserAge"
import {
  format,
  isBefore,
  isAfter,
  addMinutes,
  subMinutes,
  differenceInMilliseconds,
  differenceInMinutes,
} from "date-fns"
import { sv } from "date-fns/locale"
import braiveLogo from "assets/braive_logo.svg"
import { getOsAndBrowser } from "utils/getOsAndBrowser"
import { patientType as patientTypeEnum } from "utils/patientType"
import BookVideoMeetingDialog from "./BookVideoMeetingDialog"
import IkbtDialog from "./IkbtDialog"
import ReportIssueDialog from "./ReportIssueDialog"

const SIDEBAR_WIDTH = "270px"
const SIDEBAR_MD_WIDTH = "200px"
const MINUTES_BEFORE_MEETING_START = 15
const MINUTES_AFTER_MEETING_START = 25
const MINUTES_FROM_START_TO_SHOW_MEETING_SOON_ENDING_REMINDER = 20

const useStyles = makeStyles((theme) => ({
  meetingRoomDialog: {
    "& .MuiDialog-paperWidthLg.MuiDialog-paperFullWidth": {
      height: "100%",
    },
    "& .MuiCard-root": {
      position: "relative",
      height: "100%",
      overflowY: "hidden",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1) !important" },
  },
  meetingRoomDialogTitle: {
    padding: theme.spacing(1, 0),
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    "& span": {
      marginRight: theme.spacing(0.5),
      fontWeight: 600,
      fontSize: "0.9rem",
    },
    "& span:last-child": {
      fontWeight: 500,
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0),
      display: "inline-flex",
      alignItems: "end",
      "& svg": {
        fontSize: "1.25rem",
        marginRight: theme.spacing(0.25),
        marginBottom: theme.spacing(0.1875),
      },
    },
  },
  meetingRoomDialogFooter: {
    justifyContent: "space-between",
    "& a": {
      display: "flex",
      fontWeight: 500,
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
  },
  actionButton: {
    padding: theme.spacing(0.5, 1),
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main_light,
      "& svg": {
        color: theme.palette.primary.main_light,
      },
    },
    "&:disabled": {
      "& svg": {
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.83rem",
    },
  },
  inviteButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    textTransform: "none",
    width: "190px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
    },
    borderRadius: "18px",
    [theme.breakpoints.down("md")]: {
      width: "140px",
      fontSize: "0.7rem",
    },
  },
  inviteButtonSmall: {
    height: theme.spacing(4),
    width: "190px",
    marginTop: theme.spacing(1),
    fontSize: "0.875rem",
    [theme.breakpoints.down("md")]: {
      width: "140px",
      fontSize: "0.7rem",
    },
  },
  dialogContent: {
    display: "flex",
    height: "100%",
  },
  videoContent: ({ shouldDisplaySidebar }) => ({
    width: shouldDisplaySidebar ? `calc(100% - ${SIDEBAR_WIDTH})` : "100%",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      width: shouldDisplaySidebar ? `calc(100% - ${SIDEBAR_MD_WIDTH})` : "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  sidebarContent: {
    width: SIDEBAR_WIDTH,
    overflow: "auto",
    borderRadius: 0,
    background: theme.palette.light_gray,
    padding: theme.spacing(2, 3, 2, 3),
    borderTop: "1px solid rgba(0, 0, 0, 0.3)",
    "& .MuiTypography-caption": {
      fontSize: "0.78rem",
      fontWeight: 600,
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down("md")]: {
      width: `${SIDEBAR_MD_WIDTH})`,
      "& .MuiTypography-caption": {
        fontSize: "0.7rem",
      },
    },
  },
  contentContainerStreched: {
    height: "calc(100% - 96px)",
  },
  contentContainerShortened: {
    height: "calc(100% - 166px)",
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 166px)",
    },
  },
  sidebarContentShower: {
    display: "flex",
    paddingTop: "0.3rem",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      rotate: "180deg",
    },
  },
  sidebarContentHider: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 0,
    paddingTop: "0.3rem",
    height: "100%",
    display: "flex",
    cursor: "pointer",
  },
  braiveInvite: {
    position: "absolute",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "160px !important",
    justifyContent: "space-evenly",
    borderRadius: theme.spacing(1.25),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "160px !important",
    },
  },
  braiveInviteSmall: {
    bottom: theme.spacing(2.5),
    height: "120px !important",
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  braiveInviteHidden: {
    display: "none",
  },
  sidebarTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    lineHeight: 1.3,

    [theme.breakpoints.down("md")]: {
      fontSize: "0.90rem",
    },
    [theme.breakpoints.down(1290)]: {
      marginBottom: theme.spacing(2),
    },
  },
  braiveInviteTitle: {
    color: theme.palette.primary.main,
    lineHeight: 1.2,
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 600,
    [theme.breakpoints.down(970)]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down(750)]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
  },
  braiveInviteTitleLarge: {
    fontSize: "0.9rem",
    fontWeight: 600,
  },
  braiveInviteTitleSmall: {
    fontSize: "0.7rem",
    fontWeight: 500,
  },
  braiveInviteSubtitle: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "0.9rem",
    [theme.breakpoints.down(970)]: {
      fontSize: "0.85rem",
    },
    [theme.breakpoints.down(750)]: {
      fontSize: "0.78rem",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.6rem",
    },
    "@media (max-height: 100px)": {
      display: "none",
    },
  },
  braiveInviteSubtitleLarge: {
    fontSize: "0.85rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.78rem",
    },
  },
  braiveInviteSubtitleSmall: {
    fontSize: "0.6rem",
  },
  sidebarSubtitle: {
    fontSize: "0.8rem",
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

const MeetingRoomDialog = ({
  onClose,
  openDialog,
  meetingRoomUrl,
  meetingStart,
  caregiverId,
  caregiverKaddioId,
  kaddioForms,
  patientId,
  patientFullName,
  patientKaddioId,
  patientKaddioContactId,
  patientVisitNumber,
  patientIsSormland,
  patientSocialSecurity,
  patientGender,
  patientZipCode,
  sessionId,
  chatId,
  meetingId,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const [openMaterialPicker, setOpenMaterialPicker] = useState(false)
  const [openBookVideoMeetingDialog, setOpenBookVideoMeetingDialog] =
    useState(false)
  const [openReportIssueDialog, setOpenReportIssueDialog] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState(null)
  const [anchorMaterialEl, setAnchorMaterialEl] = useState(null)
  const [anchorBookVideoMeetingEl, setAnchorBookVideoMeetingEl] = useState(null)
  const [isBookVideoMeetingDisabled, setIsBookVideoMeetingDisabled] =
    useState(false)
  const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState(false)
  const [userHiddenSidebar, setUserHiddenSidebar] = useState(false)
  const classes = useStyles({ shouldDisplaySidebar })
  const [isIkbtModalOpen, setIkbtModalOpen] = useState(false)
  const [height, setHeight] = useState(window.innerHeight)

  const definedVisits = [2, 3, 6, 15]
  const minRecommendedMinutesToBookRevisit = 0
  const maxRecommendedMinutesToBookRevisit = 60

  const checkJoiningTime = () => {
    const currentTime = new Date()
    const meetingTime = new Date(meetingStart)
    const isJoiningEarly = isBefore(
      currentTime,
      subMinutes(meetingTime, MINUTES_BEFORE_MEETING_START)
    )
    const isJoiningLate = isAfter(
      currentTime,
      addMinutes(meetingTime, MINUTES_AFTER_MEETING_START)
    )
    if (isJoiningEarly || isJoiningLate) {
      dispatch(
        showAlert({
          type: "warning",
          message: `Obs! Detta samtal börjar ${format(meetingTime, "d MMM", {
            locale: sv,
          }).replace(/\./g, "")} ${format(meetingTime, "HH:mm", {
            locale: sv,
          })}. Kontrollera att det är rätt samtal.`,
        })
      )
    }
  }

  const fetchAvailableTimeSlotsIfValidJoinTime = () => {
    const currentTime = new Date()
    const meetingStartTime = new Date(meetingStart)
    const thirtyMinBeforeMeetingStart = subMinutes(meetingStartTime, 30)
    const twentyMinAfterMeetingStart = addMinutes(meetingStartTime, 20)
    if (
      currentTime >= thirtyMinBeforeMeetingStart &&
      currentTime <= twentyMinAfterMeetingStart
    ) {
      dispatch(fetchAvailableTimeSlots(meetingStart))
    }
  }

  const handleEndMeetingReminderTimer = () => {
    const currentTime = new Date()
    const meetingTime = new Date(meetingStart)
    const messageShowTime = addMinutes(
      meetingTime,
      MINUTES_FROM_START_TO_SHOW_MEETING_SOON_ENDING_REMINDER
    )
    const isMeetingAboutToEnd =
      currentTime.getTime() < messageShowTime.getTime()

    if (isMeetingAboutToEnd) {
      const timeoutDifference = differenceInMilliseconds(
        new Date(messageShowTime),
        currentTime
      )

      return timeoutDifference
    }

    return 0
  }

  useEffect(() => {
    setHeight(window.innerHeight)
  }, [window.innerHeight])

  useEffect(() => {
    if (!openDialog) {
      setIsBookVideoMeetingDisabled(false)
      setUserHiddenSidebar(false)
      setSelectedMaterial(null)
    } else {
      const messageTimeout = handleEndMeetingReminderTimer()
      let timeoutId
      if (messageTimeout) {
        timeoutId = setTimeout(() => {
          dispatch(
            showAlert({
              type: "info",
              message: (
                <>
                  <Typography>(5 min kvar)</Typography>
                  <Typography>
                    Vi vill påminna om att avrunda och boka ny tid.
                  </Typography>
                </>
              ),
            })
          )
        }, messageTimeout)
      }

      checkJoiningTime()
      fetchAvailableTimeSlotsIfValidJoinTime()
      return () => clearTimeout(timeoutId)
    }
  }, [openDialog])

  useEffect(() => {
    // setShouldDisplaySidebar(!isWidthSmDown)

    const handleWindowResize = () => {
      if (userHiddenSidebar) {
        setShouldDisplaySidebar(false)
      } else {
        setShouldDisplaySidebar(window.innerWidth > theme.breakpoints.values.sm)
      }
    }

    handleWindowResize()

    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [userHiddenSidebar, isWidthSmDown, theme.breakpoints.values.sm])

  const handleOpenMaterialPicker = (event) => {
    setAnchorMaterialEl(event.currentTarget)
    setOpenMaterialPicker(true)
  }

  const handleOpenBookVideoMeetingDialog = (event) => {
    if (isAfter(new Date(meetingStart), new Date())) {
      dispatch(
        showAlert({
          type: "info",
          message: "Ett nytt möte kan bokas när detta har startat.",
        })
      )
    } else {
      setAnchorBookVideoMeetingEl(event.currentTarget)
      setOpenBookVideoMeetingDialog(true)
    }
  }

  const handleKaddioFormNotGeneratedError = (errorMessage) => {
    dispatch(showAlert({ type: "error", message: errorMessage }))
    setSelectedMaterial(null)
  }

  const handleMaterialSuccessfullySent = () => {
    dispatch(
      showAlert({
        type: "success",
        message: `Du skickade material till ${patientFullName} som kan hitta det under Meddelanden.`,
      })
    )
    setSelectedMaterial(null)
  }

  const handleCloseMaterialPicker = async (material) => {
    const date = new Date()
    setOpenMaterialPicker(false)
    if (material?.id) {
      if (!patientKaddioId) {
        handleKaddioFormNotGeneratedError(
          // eslint-disable-next-line max-len
          "Formuläret kunde tyvärr inte skapas för patienten saknas i Kaddio. Om problemen består, kontakta info@dinpsykolog.se."
        )
      } else {
        const { data } = await getKaddioFormLink(
          caregiverKaddioId,
          patientKaddioId,
          material.id,
          patientId
        )

        if (data) {
          dispatch(
            postMessage(chatId, {
              userId: caregiverId,
              data: `${data}#${material.description.replace(/ /g, "&")}`,
              dataType: dataType.TEXT,
              fromUserId: caregiverId,
              toUserId: patientId,
              createdAt: date.toISOString(),
              seen: false,
              sessionId,
            })
          ).then(() => {
            handleMaterialSuccessfullySent()
          })
        } else {
          handleKaddioFormNotGeneratedError(
            // eslint-disable-next-line max-len
            "Formuläret kunde inte genereras. Vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
          )
        }
      }
    } else if (material?.url) {
      dispatch(
        postMessage(chatId, {
          userId: caregiverId,
          data: material.url,
          dataType: dataType.TEXT,
          fromUserId: caregiverId,
          toUserId: patientId,
          createdAt: date.toISOString(),
          seen: false,
          sessionId,
        })
      ).then(() => {
        handleMaterialSuccessfullySent()
        if (caregiverKaddioId && patientKaddioContactId) {
          dispatch(
            postSharedMaterialToKaddioContact(
              caregiverKaddioId,
              patientKaddioContactId,
              {
                materialName: material.description,
                sessionId,
              }
            )
          )
        }
      })
    }
  }

  const handleConfirmVideoMeetingBooking = (start, slotTimeZone) => {
    const minutesDifferenceBetweenMeetingStartAndRevisitBooking =
      differenceInMinutes(new Date(), new Date(meetingStart))
    const isRevisitBookedWithinRecommendedInterval =
      minutesDifferenceBetweenMeetingStartAndRevisitBooking >=
        minRecommendedMinutesToBookRevisit &&
      minutesDifferenceBetweenMeetingStartAndRevisitBooking <=
        maxRecommendedMinutesToBookRevisit

    bookVideoMeetingByCaregiver({
      patientId,
      start,
      slotTimeZone,
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      caregiverKaddioId,
      patientKaddioContactId,
      isRevisitBookedWithinRecommendedInterval,
    })
      .then((response) => {
        const { meetingPaid, patientType } = response.data
        setOpenBookVideoMeetingDialog(false)
        setIsBookVideoMeetingDisabled(true)

        dispatch(
          showAlert({
            type: "success",
            message: (
              <Typography>
                Du bokade ett videosamtal (
                {format(new Date(start), "d MMM", {
                  locale: sv,
                }).replace(/\./g, "")}{" "}
                kl.{" "}
                {format(new Date(start), "HH:mm", {
                  locale: sv,
                })}
                ) åt patient {patientFullName}.
                {meetingPaid && patientType === patientTypeEnum.PAYING && (
                  <Typography
                    component="p"
                    variant="caption"
                    sx={{ fontStyle: "italic" }}
                  >
                    OBS! Informera patient om att faktura skickas efter att
                    nästa besök genomförts
                  </Typography>
                )}
              </Typography>
            ),
          })
        )

        if (meetingPaid) {
          const patientAge = getUserAge(patientSocialSecurity, true)
          const userAgent = window?.navigator?.userAgent
          const { os, browser } = getOsAndBrowser(userAgent)

          trackEvent("sessionCreated", {
            userId: patientId,
            userAge: patientAge,
            gender: patientGender === "female" ? "F" : "M",
            zipCode: patientZipCode || "",
            patientType,
            isSormland: patientIsSormland,
            newCustomer: false,
            sessionType: "video",
            bookedByPatient: false,
            userAgent,
            os,
            browser,
          })
        }
      })
      .catch(() => {
        setOpenBookVideoMeetingDialog(false)
        dispatch(
          showAlert({
            type: "error",
            message: "Något gick fel. Kontakta supporten.",
          })
        )
      })
  }

  const handleInvitePatientToIkbt = (event) => {
    invitePatientToIkbt(patientId)
      .then(() => {
        dispatch(
          showAlert({
            type: "success",
            // eslint-disable-next-line max-len
            message: "Patienten har nu fått en inbjudningslänk till sin email!",
          })
        )
        setIkbtModalOpen(false)
      })
      .catch(() => {
        event.target.disabled = false
        dispatch(
          showAlert({
            type: "error",
            message:
              "Det gick tyvärr inte att bjuda in patienten. Vänligen försök igen!",
          })
        )
        setIkbtModalOpen(false)
      })
  }

  const getShortenedPatientFullName = () => {
    const [patientFirstName, ...patientRemainingNames] =
      patientFullName.split(/[\s-]/)
    const patientRemainingNamesFirstLetters = patientRemainingNames.map(
      (remainingName) => `${remainingName.charAt(0)}.`
    )
    return `${patientFirstName} ${patientRemainingNamesFirstLetters.join(" ")}`
  }

  const generateSidebarTitle = () => {
    const patientVisitNumberInt = parseInt(patientVisitNumber)
    return (
      <Typography className={classes.sidebarTitle}>
        {patientVisitNumberInt === 1
          ? "Tips att tänka på under första samtalet"
          : definedVisits.includes(patientVisitNumberInt)
          ? `Tips att tänka på under samtal ${patientVisitNumberInt}`
          : "Tips att tänka på under samtalet"}
      </Typography>
    )
  }

  const generateWelcomeText = () => {
    const patientVisitNumberInt = parseInt(patientVisitNumber)
    switch (patientVisitNumberInt) {
      case 1:
        return [
          "● Ramar",
          "● Har patienten skapat ett konto i Braive?",
          "  ○ Om ja: Uppmuntra fylla i MHC.",
          "  ○ Om nej: Instruera/hjälp patienten att skapa ett konto i slutet av samtalet.",
        ]
      case 2:
        return [
          "● Dela agenda, ställa fler frågor idag",
          "● Hur kändes förra samtalet?",
          "● Har fler saker dykt upp?",
        ]
      case 3:
      case 6:
      case 15:
        return ["● Check-in", "● Agenda, kom överens"]
      default:
        return ["● Hur kändes förra samtalet?", "● Patientfrågor"]
    }
  }

  const generateCallText = () => {
    const patientVisitNumberInt = parseInt(patientVisitNumber)
    switch (patientVisitNumberInt) {
      case 1:
        return [
          "● Sökorsak",
          "● Önskemål",
          "● Validera + förstärk",
          "● Suicidcheck",
        ]
      case 2:
        return [
          "● Anamnes + diagnostik",
          "● Validera (spegla, sammanfatta, stäm av)",
          "● Tilldela program",
          "● Uppmuntra till att börja jobba med första modulen direkt, så		följer ni upp det i nästa samtal",
        ]
      case 3:
        return [
          "● Följ upp behandlingsprogrammet",
          '"Var det något du kände igen dig i?"',
          "● Återge bedömning, stäm av",
          "● Gör behandlingsplan",
        ]
      case 6:
        return [
          "● Följ upp behandlingsprogrammet",
          "● Utvärdera ihop, rätt fokus?",
          "● Gör fortsatt plan ihop",
          "● Dagens fokus",
        ]
      case 15:
        return [
          "● Följ upp behandlingsprogrammet",
          "● Utvärdera ihop",
          "● Effekt (mål, symptom)?",
          "● Avsluta/gör plan för avslut",
        ]

      default:
        return [
          "● Följ upp behandlingsprogrammet",
          "● Föregående samtal/hemuppgift",
          "● Dagens fokus",
          "● Plan framåt/ny hemuppgift",
        ]
    }
  }

  const generateSidebarContent = () => (
    <>
      <Typography variant="body2" className={classes.sidebarSubtitle}>
        Välkomna{" "}
        <Typography fontWeight={500} component="span" variant="inherit">
          (0-5 min)
        </Typography>
      </Typography>
      {generateWelcomeText().map((item) => (
        <Typography variant="caption" component="p" mb={0.5}>
          {item}
        </Typography>
      ))}
      <Typography variant="body2" className={classes.sidebarSubtitle}>
        Under samtalet{" "}
        <Typography fontWeight={500} component="span" variant="inherit">
          (5-20 min)
        </Typography>
      </Typography>
      {generateCallText().map((item) => (
        <Typography variant="caption" component="p" mb={0.5}>
          {item}
        </Typography>
      ))}
      <Typography variant="body2" className={classes.sidebarSubtitle}>
        Sammanfatta{" "}
        <Typography fontWeight={500} component="span" variant="inherit">
          (20-25 min)
        </Typography>
      </Typography>
      <Typography variant="caption" component="p" mb={0.5}>
        ● Boka nästa samtal
        {parseInt(patientVisitNumber) === 15 ? "/Avslut" : ""}
      </Typography>
    </>
  )

  const toggleSidebarHandler = () => {
    setUserHiddenSidebar((prevState) => !prevState)
    setShouldDisplaySidebar((prevState) => !prevState)
  }

  const BRAIVE_INVITE_SMALLER_BREAKPOINT = 700
  const BRAIVE_INVITE_HIDDEN_BREAKPOINT = 650

  return (
    <Dialog
      fullWidth={!isWidthSmDown}
      maxWidth="lg"
      fullScreen={isWidthSmDown}
      open={openDialog}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose()
        }
      }}
      className={classes.meetingRoomDialog}
    >
      {isIkbtModalOpen && (
        <IkbtDialog
          open={isIkbtModalOpen}
          onClose={() => setIkbtModalOpen(false)}
          handleInvitePatientToIkbt={handleInvitePatientToIkbt}
        />
      )}
      <DialogTitle className={classes.meetingRoomDialogTitle}>
        {meetingStart && (
          <Typography component="span">
            {format(new Date(meetingStart), "d MMM", {
              locale: sv,
            }).replace(/\./g, "")}{" "}
            {format(new Date(meetingStart), "HH:mm", {
              locale: sv,
            })}{" "}
          </Typography>
        )}
        {patientFullName && (
          <Typography component="span">
            {getShortenedPatientFullName()}
          </Typography>
        )}
        <Typography component="span">
          <SummarizeOutlinedIcon />
          {`Session ${patientVisitNumber}`}
        </Typography>
      </DialogTitle>
      <Box className={classes.dialogContent}>
        <Card className={classes.videoContent}>
          <iframe
            title="meetingRoomVideo"
            src={`${meetingRoomUrl}&lang=sv&langOverride`}
            allow="camera;microphone"
            width="100%"
            height="100%"
          />
        </Card>
        {!shouldDisplaySidebar && (
          <Hidden smDown>
            <Card
              className={classes.sidebarContentShower}
              onClick={toggleSidebarHandler}
            >
              <ArrowForwardIosIcon fontSize="tiny" />
            </Card>
          </Hidden>
        )}
        {shouldDisplaySidebar && (
          <Card className={classes.sidebarContent}>
            <Box
              className={classes.sidebarContentHider}
              onClick={toggleSidebarHandler}
            >
              <ArrowForwardIosIcon fontSize="tiny" />
            </Box>
            <Box
              className={
                height < BRAIVE_INVITE_SMALLER_BREAKPOINT
                  ? classes.contentContainerStreched
                  : classes.contentContainerShortened
              }
            >
              {generateSidebarTitle()}
              {generateSidebarContent()}
            </Box>
            <Card
              className={[
                classes.braiveInvite,
                height < BRAIVE_INVITE_SMALLER_BREAKPOINT &&
                  classes.braiveInviteSmall,
                height < BRAIVE_INVITE_HIDDEN_BREAKPOINT &&
                  classes.braiveInviteHidden,
              ]}
            >
              <Typography
                className={`${classes.braiveInviteTitle} $
                    height < BRAIVE_INVITE_SMALLER_BREAKPOINT
                      ? classes.braiveInviteTitleSmall
                      : classes.braiveInviteTitleLarge
                  }`}
              >
                {!patientIsSormland
                  ? "Rekommendera IKBT"
                  : "Ej lämplig för IKBT"}
              </Typography>
              <Typography
                className={`${classes.braiveInviteSubtitle} ${
                  height < BRAIVE_INVITE_SMALLER_BREAKPOINT
                    ? classes.braiveInviteSubtitleSmall
                    : classes.braiveInviteSubtitleLarge
                }`}
              >
                {!patientIsSormland
                  ? "Vid depression, stress, oro, insomni, social ångest eller panikångest."
                  : "Patient från Sörmland. Kan ej bjudas in till IKBT. Rekommendera videobehandling."}
              </Typography>
              {!patientIsSormland && (
                <Button
                  className={[
                    classes.inviteButton,
                    height < BRAIVE_INVITE_SMALLER_BREAKPOINT &&
                      classes.inviteButtonSmall,
                  ]}
                  onClick={() => setIkbtModalOpen(true)}
                  variant="contained"
                >
                  Bjud in till Braive
                </Button>
              )}
            </Card>
          </Card>
        )}
      </Box>
      <DialogActions className={classes.meetingRoomDialogFooter}>
        <Box>
          <Button
            disableRipple
            startIcon={
              openMaterialPicker ? (
                <CancelOutlinedIcon color="primary" />
              ) : (
                <AddCircleOutlineOutlinedIcon color="primary" />
              )
            }
            onClick={handleOpenMaterialPicker}
            className={classes.actionButton}
          >
            Material
          </Button>
          <Button
            disableRipple
            startIcon={<VideocamIcon color="primary" />}
            onClick={handleOpenBookVideoMeetingDialog}
            className={classes.actionButton}
            disabled={isBookVideoMeetingDisabled}
          >
            Boka <Hidden smDown>videosamtal</Hidden>
          </Button>
          {patientKaddioContactId && (
            <Tooltip
              arrow
              title="Rapportera uteblivet besök, tekniska problem och övriga händelser (ex icke vårdbesök) till supporten."
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 100 }}
            >
              <Button
                disableRipple
                startIcon={<ErrorOutlineOutlinedIcon color="primary" />}
                onClick={() => setOpenReportIssueDialog(true)}
                className={classes.actionButton}
              >
                Rapportera <Hidden smDown>händelse</Hidden>
              </Button>
            </Tooltip>
          )}
          {!patientIsSormland &&
            (isWidthSmDown || height < BRAIVE_INVITE_HIDDEN_BREAKPOINT) && (
              <Button
                variant="text"
                size="large"
                className={classes.actionButton}
                component="div"
                startIcon={
                  <img
                    src={braiveLogo}
                    alt="Braive logo"
                    width="20px"
                    height="20px"
                  />
                }
                onClick={() => setIkbtModalOpen(true)}
              >
                IKBT
              </Button>
            )}
          <MaterialPicker
            selectedMaterial={selectedMaterial}
            openPicker={openMaterialPicker}
            onClose={handleCloseMaterialPicker}
            anchorEl={anchorMaterialEl}
            kaddioForms={kaddioForms}
            chatId={chatId}
            patientFullName={patientFullName}
            patientIsSormland={patientIsSormland}
            caregiverId={caregiverId}
            patientId={patientId}
          />
          <BookVideoMeetingDialog
            openDialog={openBookVideoMeetingDialog}
            onClose={() => setOpenBookVideoMeetingDialog(false)}
            anchorEl={anchorBookVideoMeetingEl}
            patientFullName={patientFullName}
            meetingStart={meetingStart}
            onConfirmBooking={(start, slotTimeZone) =>
              handleConfirmVideoMeetingBooking(start, slotTimeZone)
            }
          />
          <ReportIssueDialog
            openDialog={openReportIssueDialog}
            onClose={() => setOpenReportIssueDialog(false)}
            meeting={{
              id: meetingId,
              start: meetingStart && new Date(meetingStart),
              title: patientFullName,
              patientPnr: patientSocialSecurity,
              patientId,
            }}
          />
        </Box>
        <Box>
          <Button
            startIcon={<CancelOutlinedIcon color="primary" />}
            onClick={() => onClose()}
            className={`${classes.actionButton} ${classes.closeButton}`}
          >
            Stäng
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ user, kaddioForms }) => {
  const { userId: caregiverId, kaddioId: caregiverKaddioId } = user
  return {
    caregiverId,
    caregiverKaddioId,
    kaddioForms,
  }
}

export default connect(mapStateToProps)(MeetingRoomDialog)
