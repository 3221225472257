/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  showAlert,
  addCalendarAvailability,
  editCalendarAvailability,
  removeCalendarAvailability,
} from "redux/actions"
import { useDispatch } from "react-redux"
import { createAvailability } from "api/createAvailability"
import { updateAvailability } from "api/updateAvailability"
import { deleteAvailability } from "api/deleteAvailability"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  TextField,
} from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import DatePicker from "components/DatePicker"
import Select from "components/Select"
import availabilityValidationSchema from "validation/availabilityValidation"
import { addMinutes, addMonths, parse, format, isBefore } from "date-fns"
import { availabilityType as availabilityTypes } from "utils/availabilityType"
import { availabilityVisibilityType as availabilityVisibilityTypes } from "utils/availabilityVisibilityType"
import { availabilityUpdateType as updateType } from "utils/availabilityUpdateType"
// eslint-disable-next-line max-len
import { availabilityConfirmationDialogType as availabilityConfirmationDialogTypes } from "utils/availabilityConfirmationDialogType"
import { getTimeSlotsBetweenTimesWithDefinedInterval } from "utils/getTimeSlotsBetweenTimesWithDefinedInterval"
import AvailabilityConfirmationDialog from "./AvailabilityConfirmationDialog"
import AvailabilityTransformCheckbox from "./AvailabilityTransformCheckbox"

const useStyles = makeStyles((theme) => ({
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
    },
  },
  closeButton: ({ isOwnBookingDialogType, isEditMode }) => ({
    marginRight: isOwnBookingDialogType && isEditMode ? 0 : theme.spacing(1),
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  }),
  deleteButton: {
    color: theme.palette.error.main,
    float: "right",
    "& svg": {
      fontSize: "1.3rem",
    },
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  dialogTitle: ({ isEditMode }) => ({
    marginLeft: isEditMode ? theme.spacing(11) : 0,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "column",
      marginLeft: 0,
    },
  }),
  ownBookingTitle: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiInputBase-root": {
      paddingBottom: 0,
      marginBottom: theme.spacing(2),
      "& textarea": {
        fontWeight: 500,
        fontSize: "1.25rem",
        lineHeight: 1.6,
        padding: theme.spacing(1),
        borderBottom: "1px solid #D3D3D3",
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.1rem",
          lineHeight: 1.4,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.editMode .MuiInputBase-root": {
      paddingRight: 0,
      paddingLeft: theme.spacing(12),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),
        paddingLeft: 0,
      },
    },
    "& button": {
      marginRight: theme.spacing(2),
      width: "110px",
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 2, 2, "auto"),
      },
    },
  },
  dialogContent: {
    padding: theme.spacing(1),
    overflow: "hidden",
    "& .MuiFormHelperText-root.Mui-error": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  rowContainer: {
    display: "inline-flex",
    alignItems: "start",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "left",
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(0, 1),
      "&, & .MuiOutlinedInput-root": {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
  },
  dateContainer: {
    "& .MuiFormControl-root": {
      width: `calc(50% - ${theme.spacing(2)})`,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "&.ownBooking .MuiFormControl-root": {
      [theme.breakpoints.down("sm")]: {
        "&:first-of-type": {
          marginBottom: theme.spacing(2.5),
        },
      },
    },
  },
  timeContainer: {
    "& .MuiInputLabel-root": {
      minWidth: "55px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormControl-root": {
        width: `calc(50% - ${theme.spacing(1)})`,
        "&:first-of-type": {
          marginRight: theme.spacing(2),
        },
      },
      "& .MuiBox-root": {
        display: "none",
      },
    },
  },
  dialogActions: ({ isOwnBookingDialogType, isEditMode }) => ({
    margin: theme.spacing(1),
    justifyContent:
      isOwnBookingDialogType && !isEditMode ? "space-between" : "end",
    "& p": {
      color: theme.palette.warning.dark,
      fontWeight: 500,
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      "&.ownBooking": {
        flexDirection: "column",
        gap: theme.spacing(2),
        "& p": {
          marginRight: "auto",
        },
        "& .MuiBox-root": {
          marginLeft: "auto",
        },
      },
    },
  }),
  divider: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2.5),
    },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
    ".MuiTooltip-popperArrow": {
      maxWidth: "180px",
    },
    "@media only screen and (max-width: 690px)": {
      ".MuiDialog-paperWidthMd": {
        width: "97%",
        margin: "0",
      },
    },
  },
}))

const timeFormat = "HH:mm"
const validTimeSlotDuration = 30
const timeIncrementInterval = 15
const oneHourInMinutes = 60
const startTimeSlots = getTimeSlotsBetweenTimesWithDefinedInterval(
  "00:00",
  "23:15",
  timeIncrementInterval
)

const AvailabilityDialog = ({
  onClose,
  openDialog,
  userId,
  minutesUntilBooking,
  availability,
  isSlotSelected,
  isOwnBookingDialogType,
  shouldDisplayReminder,
  caregiverEmploymentType,
}) => {
  const dispatch = useDispatch()
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const availabilityId = availability?.id || null
  const isEditMode = !!availabilityId
  const availabilityStart = availability?.start || null
  const availabilityEnd = availability?.end || null
  const availabilityType = availability?.type || availabilityTypes.Aldrig
  const availabilityVisibilityType = isOwnBookingDialogType
    ? availabilityVisibilityTypes.OwnBooking
    : availability?.visibilityType || availabilityVisibilityTypes.Standard
  const availabilityTransform = isOwnBookingDialogType
    ? false
    : availability?.transform ?? true
  const availabilityTitle = availability?.title || null
  const availabilityDescription =
    availability?.description || (isEditMode ? " " : null)
  const availabilityTimeZone = availability?.timeZone || timeZone
  const editingAvailabilityThatStartsInPast =
    isEditMode && isBefore(new Date(availabilityStart), new Date())
  const defaultValues = {
    availabilityDate: availabilityStart,
    availabilityStartTime: availabilityStart
      ? format(availabilityStart, timeFormat)
      : null,
    availabilityEndTime: availabilityEnd
      ? format(availabilityEnd, timeFormat)
      : null,
    availabilityType,
    availabilityVisibilityType,
    availabilityTransform,
    availabilityTitle,
    availabilityDescription,
  }
  const editingOwnBooking = isEditMode && isOwnBookingDialogType
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const endTimeSlots = getTimeSlotsBetweenTimesWithDefinedInterval(
    selectedStartTime
      ? format(
          addMinutes(
            parse(selectedStartTime, timeFormat, new Date()),
            validTimeSlotDuration
          ),
          timeFormat
        )
      : "00:30",
    "23:45",
    selectedStartTime ? validTimeSlotDuration : timeIncrementInterval
  )
  const resolver = (data) => {
    data.availabilityStartTime = data.availabilityStartTime
      ? parse(data.availabilityStartTime, timeFormat, new Date())
      : null
    data.availabilityEndTime =
      data.availabilityEndTime &&
      endTimeSlots.includes(data.availabilityEndTime)
        ? parse(data.availabilityEndTime, timeFormat, new Date())
        : null
    data.availabilityTitle =
      data.availabilityVisibilityType === availabilityVisibilityTypes.OwnBooking
        ? data.availabilityTitle || "Egen bokning"
        : null
    const { error, value } = availabilityValidationSchema(
      data,
      editingAvailabilityThatStartsInPast,
      minutesUntilBooking
    )
    const responseValues = error ? {} : value
    const responseErrors = error
      ? error.details.reduce(
          (previous, currentError) => ({
            ...previous,
            [currentError.path[0]]: currentError,
          }),
          {}
        )
      : {}

    return {
      values: responseValues,
      errors: responseErrors,
    }
  }
  const {
    control,
    handleSubmit,
    formState,
    reset,
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver,
    defaultValues,
  })
  const [
    openAvailabilityConfirmationDialog,
    setOpenAvailabilityConfirmationDialog,
  ] = useState(false)
  const [
    availabilityConfirmationDialogType,
    setAvailabilityConfirmationDialogType,
  ] = useState(null)
  const selectedAvailabilityStartTime = watch("availabilityStartTime")
  const selectedAvailabilityVisibilityType = watch("availabilityVisibilityType")
  const { isSubmitting, isDirty } = formState
  const isRecurring =
    availabilityType === availabilityTypes["Varje arbetsdag"] ||
    availabilityType === availabilityTypes["Varje vecka"]
  const nonRecurringAvailabilityUpdateType = updateType.ALL
  const classes = useStyles({ isEditMode, isOwnBookingDialogType })

  useEffect(() => {
    reset(defaultValues)
  }, [availability, isOwnBookingDialogType])

  useEffect(() => {
    if (!isEditMode) {
      setValue(
        "availabilityTransform",
        selectedAvailabilityVisibilityType !==
          availabilityVisibilityTypes.Standard
      )
    }
  }, [selectedAvailabilityVisibilityType])

  useEffect(() => {
    setSelectedStartTime(selectedAvailabilityStartTime)
  }, [selectedAvailabilityStartTime])

  const handleCloseDialog = () => {
    onClose()
    reset(defaultValues)
  }

  const getFutureEndTimeSlots = () => {
    const currentTime = new Date()
    const currentTimeInt =
      currentTime.getHours() * 100 + currentTime.getMinutes()
    return endTimeSlots.filter((timeSlot) => {
      const timeSlotInt = parseInt(timeSlot.replace(":", ""))
      return timeSlotInt > currentTimeInt
    })
  }

  const handleErrorMessage = (error) => {
    const errorMessage = error?.response?.data || "Något gick fel"
    dispatch(showAlert({ type: "error", message: errorMessage }))
    const formValues = getValues()
    reset(formValues, {
      keepIsSubmitted: false,
    })
  }

  const handleDeleteAvailability = (availabilityUpdateType) => {
    deleteAvailability(userId, availabilityId, {
      start: availabilityStart,
      end: availabilityEnd,
      type: availabilityType,
      updateType: availabilityUpdateType,
      timeZone: availabilityTimeZone,
    })
      .then(({ data: availabilitiesUnderMeetings }) => {
        dispatch(
          removeCalendarAvailability(
            availabilityId,
            availabilityStart,
            availabilityUpdateType,
            isOwnBookingDialogType,
            availabilitiesUnderMeetings
          )
        )
      })
      .catch((error) => {
        handleErrorMessage(error)
      })
      .finally(() => {
        setOpenAvailabilityConfirmationDialog(false)
        handleCloseDialog()
      })
  }

  const handleOpenUpdateConfirmationDialog = () => {
    setAvailabilityConfirmationDialogType(
      availabilityConfirmationDialogTypes.UPDATE
    )
    setOpenAvailabilityConfirmationDialog(true)
  }

  const handleDeleteButtonClick = () => {
    if (editingAvailabilityThatStartsInPast && !isOwnBookingDialogType) {
      dispatch(
        showAlert({
          type: "info",
          message:
            "Man kan inte ta bort tillgänglighet bakåt i tiden, men den kan editeras.",
        })
      )
    } else {
      setAvailabilityConfirmationDialogType(
        availabilityConfirmationDialogTypes.DELETE
      )
      setOpenAvailabilityConfirmationDialog(true)
    }
  }

  const filterTimeSlotsByAvailabilityStartTime = () => {
    const availabilityStartMinutes = parseInt(format(availabilityStart, "mm"))
    const availabilityStartPlusSlotDurationMinutes =
      (availabilityStartMinutes + validTimeSlotDuration) % oneHourInMinutes
    return startTimeSlots.filter((slot) => {
      const slotMinutes = parseInt(slot.split(":")[1])
      return (
        slotMinutes === availabilityStartMinutes ||
        slotMinutes === availabilityStartPlusSlotDurationMinutes
      )
    })
  }

  const onSubmit = (formData, availabilityUpdateType) => {
    const {
      availabilityDate,
      availabilityStartTime,
      availabilityEndTime,
      availabilityType: type,
      availabilityVisibilityType: visibilityType,
      availabilityTransform: transform,
      availabilityTitle: title,
      availabilityDescription: description,
    } = formData
    const start = new Date(
      new Date(availabilityDate.getTime()).setHours(
        availabilityStartTime.getHours(),
        availabilityStartTime.getMinutes(),
        availabilityStartTime.getSeconds()
      )
    )
    const end = new Date(
      new Date(availabilityDate.getTime()).setHours(
        availabilityEndTime.getHours(),
        availabilityEndTime.getMinutes(),
        availabilityEndTime.getSeconds()
      )
    )

    if (isEditMode) {
      return new Promise(() => {
        updateAvailability(userId, availabilityId, {
          start,
          end,
          type,
          updateType: availabilityUpdateType,
          visibilityType,
          timeZone: availabilityTimeZone,
        })
          .then(
            ({ data: updatedAvailabilityAndAvailabilitiesUnderMeetings }) => {
              dispatch(
                editCalendarAvailability(
                  updatedAvailabilityAndAvailabilitiesUnderMeetings
                )
              )
              handleCloseDialog()
            }
          )
          .catch((error) => {
            handleErrorMessage(error)
          })
      })
    }
    return new Promise(() => {
      createAvailability(userId, {
        start,
        end,
        type,
        visibilityType,
        transform,
        timeZone,
        title,
        description,
      })
        .then(({ data }) => {
          dispatch(addCalendarAvailability(data, isOwnBookingDialogType))
          handleCloseDialog()
        })
        .catch((error) => {
          handleErrorMessage(error)
        })
    })
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={onClose}>
        {isOwnBookingDialogType ? (
          <Box
            className={`${classes.ownBookingTitle} ${
              isEditMode ? "editMode" : ""
            }`}
          >
            <Controller
              name="availabilityTitle"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState?.error?.message}
                  {...field}
                  autoFocus
                  fullWidth
                  multiline
                  placeholder="Skriv rubrik"
                  inputProps={{
                    maxLength: 50,
                  }}
                  disabled={isEditMode}
                />
              )}
            />
            {isEditMode && (
              <Button
                className={classes.deleteButton}
                onClick={() => handleDeleteButtonClick()}
              >
                <DeleteIcon />
                Radera
              </Button>
            )}
          </Box>
        ) : (
          <DialogTitle className={classes.dialogTitle}>
            {isEditMode ? (
              <>
                Uppdatera tillgänglighet
                <Button
                  className={classes.deleteButton}
                  onClick={() => handleDeleteButtonClick()}
                >
                  <DeleteIcon />
                  Radera
                </Button>
              </>
            ) : (
              "Lägga till tillgänglighet"
            )}
            {!isEditMode && shouldDisplayReminder && (
              <Typography mt={2} color="error" fontWeight={600}>
                Du har inga lediga tider kvar. Vänligen lägg till fler tider åt
                dina patienter.
              </Typography>
            )}
          </DialogTitle>
        )}
        <form
          onSubmit={handleSubmit((data) =>
            onSubmit(data, nonRecurringAvailabilityUpdateType)
          )}
        >
          <DialogContent className={classes.dialogContent}>
            <Box
              className={`${classes.rowContainer} ${classes.dateContainer} ${
                isOwnBookingDialogType ? "ownBooking" : ""
              }`}
            >
              <DatePicker
                disabled={isEditMode}
                name="availabilityDate"
                controller={control}
                label="Datum"
                minDate={new Date()}
                maxDate={addMonths(new Date(), 3)}
              />
              {isOwnBookingDialogType && (
                <Select
                  name="availabilityType"
                  data={Object.entries(availabilityTypes)}
                  controller={control}
                  label="Upprepa"
                  labelId="repeat-availability"
                  disabled={isEditMode}
                  availabilityTypeSelect
                />
              )}
            </Box>
            <Box className={classes.divider} />
            <Box className={`${classes.rowContainer} ${classes.timeContainer}`}>
              <Select
                disabled={
                  editingAvailabilityThatStartsInPast || editingOwnBooking
                }
                name="availabilityStartTime"
                controller={control}
                label="Starttid"
                data={
                  isEditMode
                    ? filterTimeSlotsByAvailabilityStartTime()
                    : startTimeSlots
                }
              />
              <Box className={classes.divider} />
              <Select
                name="availabilityEndTime"
                controller={control}
                label="Sluttid"
                data={
                  editingAvailabilityThatStartsInPast
                    ? getFutureEndTimeSlots()
                    : endTimeSlots
                }
                disabled={editingOwnBooking}
              />
            </Box>
            <Box className={classes.divider} />
            {isOwnBookingDialogType ? (
              <Box className={classes.rowContainer}>
                <Controller
                  name="availabilityDescription"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      error={Boolean(fieldState?.error)}
                      helperText={fieldState?.error?.message}
                      {...field}
                      fullWidth
                      multiline
                      rows={4}
                      label={isEditMode ? "Anteckning" : "Lägg till anteckning"}
                      inputProps={{
                        maxLength: 1000,
                      }}
                      disabled={isEditMode}
                    />
                  )}
                />
              </Box>
            ) : (
              <>
                <Box className={classes.rowContainer}>
                  <Select
                    name="availabilityType"
                    data={Object.entries(availabilityTypes)}
                    controller={control}
                    label="Upprepa"
                    labelId="repeat-availability"
                    disabled={isEditMode}
                    availabilityTypeSelect
                  />
                  <Box className={classes.divider} />
                  <Select
                    name="availabilityVisibilityType"
                    data={Object.entries(availabilityVisibilityTypes).filter(
                      // eslint-disable-next-line no-unused-vars
                      ([key, value]) =>
                        value !== availabilityVisibilityTypes.OwnBooking
                    )}
                    controller={control}
                    label="Besökstyp"
                    labelId="availability-visibility"
                    disabled={isEditMode}
                    availabilityTypeSelect
                  />
                </Box>
                {selectedAvailabilityVisibilityType !==
                  availabilityVisibilityTypes.Standard && (
                  <AvailabilityTransformCheckbox
                    caregiverEmploymentType={caregiverEmploymentType}
                    control={control}
                    isEditMode={isEditMode}
                  />
                )}
              </>
            )}
          </DialogContent>
          <DialogActions
            className={`${classes.dialogActions} ${
              isOwnBookingDialogType ? "ownBooking" : ""
            }`}
          >
            {isOwnBookingDialogType && !isEditMode && (
              <Typography variant="body2" component="p">
                Obs! Denna tid kan inte bokas av patienter
              </Typography>
            )}
            <Box>
              <Button
                onClick={() => handleCloseDialog()}
                className={classes.closeButton}
                color="default"
              >
                Stäng
              </Button>
              {(!isOwnBookingDialogType || !isEditMode) && (
                <Button
                  disabled={isSubmitting || (!isDirty && !isSlotSelected)}
                  className={classes.saveButton}
                  type={isRecurring ? "button" : "submit"}
                  onClick={
                    isRecurring ? handleOpenUpdateConfirmationDialog : null
                  }
                  variant="contained"
                >
                  Spara
                </Button>
              )}
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <AvailabilityConfirmationDialog
        openDialog={openAvailabilityConfirmationDialog}
        onClose={() => setOpenAvailabilityConfirmationDialog(false)}
        onDeleteAvailability={(availabilityUpdateType) =>
          handleDeleteAvailability(availabilityUpdateType)
        }
        onUpdateAvailability={(availabilityUpdateType) => {
          setOpenAvailabilityConfirmationDialog(false)
          handleSubmit(onSubmit)(availabilityUpdateType)
        }}
        dialogType={availabilityConfirmationDialogType}
        isRecurringAvailability={isRecurring}
        isOwnBookingType={isOwnBookingDialogType}
      />
    </>
  )
}

export default AvailabilityDialog
