import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { connect } from "react-redux"
import {
  fetchCalendarEvents,
  fetchRecommendedCaregiverAvailabilityStats,
  showAlert,
} from "redux/actions"
import {
  Calendar as BigCalendar,
  dateFnsLocalizer,
  Navigate,
  Views,
} from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import {
  addDays,
  addMinutes,
  areIntervalsOverlapping,
  format,
  getDay,
  isBefore,
  isSameWeek,
  parse,
  startOfWeek,
  subDays,
} from "date-fns"
import { sv } from "date-fns/locale"
import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useLocalStorage } from "utils/useLocalStorage"
import MeetingDialog from "components/MeetingDialog"
import MeetingRoomDialog from "components/MeetingRoomDialog"
import LeaveMeetingConfirmationDialog from "components/LeaveMeetingConfirmationDialog"
import { availabilityVisibilityType } from "utils/availabilityVisibilityType"
import { getCaregiverTimeSlotsAvailable } from "api/getCaregiverTimeSlotsAvailable"
import { getTimeLimitStringForAddingAvailability } from "utils/getTimeLimitStringForAddingAvailability"
import { generateTimeSlots } from "utils/generateTimeSlots"
import ReportIssueDialog from "components/ReportIssueDialog"
import CalendarToolbar from "./components/CalendarToolbar"
import AvailabilityDialog from "./components/AvailabilityDialog"
import MeetingEvent from "./components/MeetingEvent"
import WeeklyAvailabilityStats from "./components/WeeklyAvailabilityStats"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  upperSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      "& p": { fontSize: "0.8rem" },
    },
  },
  calendarContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.5),
    },
    "& table.rbc-agenda-table tbody > tr > td": {
      verticalAlign: "middle",
    },
  },
}))

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales: { sv },
})
const calendarViews = Object.keys(Views).map((view) => Views[view])

const Calendar = ({
  dispatch,
  userId,
  caregiverMinutesUntilBooking,
  calendarEvents,
  caregiverEmploymentType,
  alert,
  recommendedAvailabilityStats,
}) => {
  const classes = useStyles()
  const [searchParams] = useSearchParams()
  const calendarViewParam = searchParams.get("calendar-view")
  const bookings = calendarEvents?.bookings || []
  const paidBookings =
    calendarEvents?.bookings?.filter(
      (booking) => booking.isSuccessfulPayment
    ) || []
  const availabilities = calendarEvents?.available || []
  const [calendarView, setCalendarView] = useState(Views.AGENDA)
  const [numberOfWeeklyAvailabilities, setNumberOfWeeklyAvailabilities] =
    useState(recommendedAvailabilityStats?.availabilitiesAmount)
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false)
  const [
    isOwnBookingAvailabilityDialogType,
    setIsOwnBookingAvailabilityDialogType,
  ] = useState(false)
  const [openMeetingDialog, setOpenMeetingDialog] = useState(false)
  const [
    openLeaveMeetingConfirmationDialog,
    setOpenLeaveMeetingConfirmationDialog,
  ] = useState(false)
  const [openMeetingRoomDialog, setOpenMeetingRoomDialog] = useLocalStorage(
    "videoMeetingModalOpen",
    false
  )
  const [availability, setAvailability] = useState(null)
  const [meeting, setMeeting] = useState(null)
  const [meetingRoomData, setMeetingRoomData] = useLocalStorage(
    "videoMeetingData",
    null
  )
  const [
    shouldDisplayReminderInAvailabilityDialog,
    setShouldDisplayReminderInAvailabilityDialog,
  ] = useState(false)
  const meetingData = meetingRoomData ? JSON.parse(meetingRoomData) : null
  const [isSlotSelected, setIsSlotSelected] = useState(false)
  const timezoneOffset = calendarDate.getTimezoneOffset()
  let timezoneOffsetMinutes
  let timezoneOffsetHours
  let timezoneOffsetSign
  if (timezoneOffset) {
    timezoneOffsetSign = timezoneOffset < 0 ? "+" : "-"
    const absoluteTimezoneOffset = Math.abs(timezoneOffset)
    timezoneOffsetMinutes = absoluteTimezoneOffset % 60
    timezoneOffsetHours = Math.floor(absoluteTimezoneOffset / 60)
  }
  const defaultMinutesUntilBooking = 15
  const minutesUntilBooking =
    caregiverMinutesUntilBooking ?? defaultMinutesUntilBooking
  const [openReportIssueDialog, setOpenReportIssueDialog] = useState(false)

  useEffect(() => {
    if (calendarView === Views.WEEK) {
      dispatch(fetchRecommendedCaregiverAvailabilityStats(calendarDate))
    }
  }, [dispatch, calendarDate, availabilities, calendarView])

  useEffect(() => {
    const getCaregiverWeeklyTimeslots = () => {
      const weeklyAvailabilities = availabilities.filter((item) =>
        isSameWeek(calendarDate, item.start, { weekStartsOn: 1 })
      )
      const countedAvailabilityTypes = [
        availabilityVisibilityType.Standard,
        availabilityVisibilityType.Återbesök,
        availabilityVisibilityType.Nybesök,
      ]
      let numberOfTimeslots = 0
      weeklyAvailabilities.forEach((item) => {
        if (countedAvailabilityTypes.includes(item.visibilityType)) {
          const availabilityTimeslots = generateTimeSlots(item.start, item.end)
          numberOfTimeslots += availabilityTimeslots.length
        }
      })
      setNumberOfWeeklyAvailabilities(numberOfTimeslots)
    }
    if (calendarView === Views.WEEK) {
      getCaregiverWeeklyTimeslots()
    }
  }, [calendarView, calendarDate, availabilities])

  useEffect(() => {
    if (calendarViewParam && calendarViews.includes(calendarViewParam)) {
      setCalendarView(calendarViewParam)
    }
  }, [calendarViewParam])

  useEffect(() => {
    const beforeUnloadAction = () => {
      setMeetingRoomData(null)
      setOpenMeetingRoomDialog(false)
    }
    // if browser tab was closed, delete meeting data from local storage
    window.addEventListener("beforeunload", beforeUnloadAction)
    return () => window.removeEventListener("beforeunload", beforeUnloadAction)
  }, [])

  useEffect(() => {
    const checkCaregiverSlotsAvailable = async () => {
      sessionStorage.setItem("checkedCaregiverSlotsAvailable", true)
      const { data } = await getCaregiverTimeSlotsAvailable(userId)
      if (data?.available?.length === 0) {
        setIsOwnBookingAvailabilityDialogType(false)
        setShouldDisplayReminderInAvailabilityDialog(true)
        setOpenAvailabilityDialog(true)
      }
    }
    if (userId && !sessionStorage.getItem("checkedCaregiverSlotsAvailable")) {
      checkCaregiverSlotsAvailable()
    }
  }, [userId])

  const onViewChange = useCallback(
    (newView) => {
      if (newView === Views.AGENDA) {
        setCalendarDate(new Date())
      }
      setCalendarView(newView)
    },
    [setCalendarView]
  )

  const handleOpenAvailabilityDialog = (shouldOpenOwnBookingDialog) => {
    if (alert) {
      dispatch(alert.hide())
    }
    setIsSlotSelected(false)
    setAvailability(null)
    setIsOwnBookingAvailabilityDialogType(shouldOpenOwnBookingDialog)
    setOpenAvailabilityDialog(true)
  }

  const handleCloseAvailabilityDialog = () => {
    setOpenAvailabilityDialog(false)
    if (shouldDisplayReminderInAvailabilityDialog) {
      setShouldDisplayReminderInAvailabilityDialog(false)
    }
  }

  const handleOpenReportIssueDialog = (event, selectedMeeting) => {
    event.stopPropagation()
    setMeeting(selectedMeeting)
    setOpenReportIssueDialog(true)
  }

  const handleJoinMeeting = async (meetingInfo) => {
    await setMeetingRoomData(meetingInfo ? JSON.stringify(meetingInfo) : null)
    setOpenMeetingDialog(false)
    setOpenMeetingRoomDialog(true)
  }

  const handleSelectEvent = useCallback(
    (event, displayedAlert) => {
      if (displayedAlert) {
        dispatch(displayedAlert.hide())
      }
      // meeting event has resource property, availability event doesn't
      if (event.resource) {
        if (!event.isSuccessfulPayment) return
        setMeeting(event)
        setOpenMeetingDialog(true)
      } else {
        const conditionForNotAllowedAvailabilityEdit = isBefore(
          event.end,
          new Date()
        )
        const isOwnBookingEvent =
          event.visibilityType === availabilityVisibilityType.OwnBooking
        const infoMessageForNotAllowedAvailabilityEdit = `Man kan inte editera ${
          isOwnBookingEvent ? "egen bokning" : "tillgänglighet"
        } bakåt i tiden`
        if (conditionForNotAllowedAvailabilityEdit) {
          dispatch(
            showAlert({
              type: "info",
              message: infoMessageForNotAllowedAvailabilityEdit,
            })
          )
          return
        }
        setIsSlotSelected(false)
        setAvailability(event)
        setIsOwnBookingAvailabilityDialogType(isOwnBookingEvent)
        setOpenAvailabilityDialog(true)
      }
    },
    [
      setIsSlotSelected,
      setAvailability,
      setIsOwnBookingAvailabilityDialogType,
      setOpenAvailabilityDialog,
      setMeeting,
      setOpenMeetingDialog,
      dispatch,
    ]
  )

  const handleSelectSlot = useCallback(
    (slotInfo, calendarAvailabilities, currentView, displayedAlert) => {
      if (displayedAlert) {
        dispatch(displayedAlert.hide())
      }
      const { start, end } = slotInfo
      const conditionForNotAllowedAvailabilityAdd = isBefore(
        start,
        addMinutes(new Date(), minutesUntilBooking)
      )

      const infoMessageForNotAllowedAvailabilityAdd = minutesUntilBooking
        ? `Man kan tidigast lägga till tillgänglighet ${getTimeLimitStringForAddingAvailability(
            minutesUntilBooking
          )} från och med nu`
        : "Man kan inte lägga till tillgänglighet bakåt i tiden"
      let slotOverlapsAvailability = false
      calendarAvailabilities.forEach((calendarAvailability) => {
        if (
          areIntervalsOverlapping(
            { start, end },
            { start: calendarAvailability.start, end: calendarAvailability.end }
          )
        )
          slotOverlapsAvailability = true
      })
      if (currentView !== "month" && !slotOverlapsAvailability) {
        if (conditionForNotAllowedAvailabilityAdd) {
          dispatch(
            showAlert({
              type: "info",
              message: infoMessageForNotAllowedAvailabilityAdd,
            })
          )
        } else {
          setIsSlotSelected(true)
          setAvailability({ start, end })
          setIsOwnBookingAvailabilityDialogType(false)
          setOpenAvailabilityDialog(true)
        }
      }
    },
    [
      minutesUntilBooking,
      setIsSlotSelected,
      setAvailability,
      setIsOwnBookingAvailabilityDialogType,
      setOpenAvailabilityDialog,
      dispatch,
    ]
  )

  const onNavigate = useCallback(
    (date, view, action) => {
      if (view === Views.AGENDA) {
        switch (action) {
          case Navigate.NEXT:
            setCalendarDate(addDays(new Date(date), 1))
            break
          case Navigate.PREVIOUS:
            setCalendarDate(subDays(new Date(date), 1))
            break
          default:
            setCalendarDate(new Date())
        }
      } else {
        setCalendarDate(date)
      }
    },
    [setCalendarDate]
  )

  const eventStyleGetter = (event) => {
    let backgroundColor
    let border
    let color
    let fontWeight
    let className = ""
    switch (event.visibilityType) {
      case availabilityVisibilityType.Standard:
        backgroundColor = "#FFF59D"
        border = "1px solid #FFF59D"
        color = "#000000"
        fontWeight = 600
        break
      case availabilityVisibilityType.Återbesök:
        backgroundColor = "#E1BEE7"
        border = "1px solid #E1BEE7"
        color = "#000000"
        fontWeight = 600
        break
      case availabilityVisibilityType.Nybesök:
        backgroundColor = "#CAEECACC"
        border = "1px solid #CAEECACC"
        color = "#000000"
        fontWeight = 600
        break
      case availabilityVisibilityType.OwnBooking:
        backgroundColor = "#F59B15"
        border = "1px solid #F59B15"
        color = "#FFFFFF"
        fontWeight = 400
        className = "ownBookingEvent"
        break
      default:
        if (event.isSuccessfulPayment) {
          if (
            parseInt(event.patientTotalSessionNumber) === 1 &&
            event.costReceiver !== "ignore"
          ) {
            backgroundColor = "#39ad48"
            border = "1px solid #39ad48"
            className = "firstVisit"
          } else {
            backgroundColor = "#3174AD"
            border = "1px solid #3174AD"
          }
          color = "#FFFFFF"
        } else {
          className = "reservedBookingEvent"
        }
    }
    const style = {
      backgroundColor,
      border,
      color,
      fontWeight,
    }

    return { style, className }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.upperSection}>
        {calendarView === Views.WEEK && (
          <WeeklyAvailabilityStats
            numberOfWeeklyAvailabilities={numberOfWeeklyAvailabilities}
            stats={recommendedAvailabilityStats}
          />
        )}
        <Box mx={2} mt={3}>
          <Typography color="primary">
            Tidszon: UTC
            {timezoneOffset
              ? `${timezoneOffsetSign}${timezoneOffsetHours}${
                  timezoneOffsetMinutes ? `:${timezoneOffsetMinutes}` : ""
                }`
              : ""}
          </Typography>
        </Box>
      </Box>
      <Box m={2} className={classes.calendarContainer}>
        <BigCalendar
          culture="sv"
          localizer={localizer}
          showMultiDayTimes
          views={calendarViews}
          view={calendarView}
          defaultDate={new Date()}
          date={calendarDate}
          timeslots={4}
          step={15}
          scrollToTime={calendarDate.setHours(5, 45, 0)}
          messages={{
            date: "Datum",
            time: "Tid",
            event: "Möte",
            noEventsInRange: "Det finns inga möten i det här intervallet.",
            showMore: (total) => `+${total} mer`,
          }}
          onView={onViewChange}
          selectable
          components={{
            agenda: {
              event: (props) =>
                MeetingEvent({ handleOpenReportIssueDialog, ...props }),
            },
            toolbar: (props) =>
              CalendarToolbar({ handleOpenAvailabilityDialog, ...props }),
          }}
          backgroundEvents={availabilities}
          events={
            [Views.AGENDA, Views.MONTH].includes(calendarView)
              ? paidBookings
              : bookings
          }
          eventPropGetter={eventStyleGetter}
          onSelectEvent={(event) => handleSelectEvent(event, alert)}
          onSelectSlot={(slotInfo) =>
            handleSelectSlot(slotInfo, availabilities, calendarView, alert)
          }
          length={0}
          formats={{
            agendaHeaderFormat: ({ start }) =>
              `${format(new Date(start), "d MMMM yyyy", {
                locale: sv,
              })}`,
            eventTimeRangeStartFormat: ({ start }, culture) =>
              `${localizer.format(start, "HH:mm", culture)} - 00:00`,
            eventTimeRangeEndFormat: ({ end }, culture) =>
              `00:00 - ${localizer.format(end, "HH:mm", culture)}`,
            agendaTimeFormat: (time, culture) =>
              `${localizer.format(time, "HH:mm", culture)} - 00:00`,
          }}
          onNavigate={onNavigate}
        />
      </Box>
      <AvailabilityDialog
        openDialog={openAvailabilityDialog}
        onClose={() => handleCloseAvailabilityDialog()}
        userId={userId}
        caregiverEmploymentType={caregiverEmploymentType}
        availability={availability}
        isSlotSelected={isSlotSelected}
        isOwnBookingDialogType={isOwnBookingAvailabilityDialogType}
        shouldDisplayReminder={shouldDisplayReminderInAvailabilityDialog}
        minutesUntilBooking={minutesUntilBooking}
      />
      <MeetingDialog
        openDialog={openMeetingDialog}
        onClose={() => setOpenMeetingDialog(false)}
        meetingId={meeting?.id}
        userId={userId}
        onJoinMeeting={handleJoinMeeting}
      />
      <MeetingRoomDialog
        openDialog={openMeetingRoomDialog}
        onClose={() => setOpenLeaveMeetingConfirmationDialog(true)}
        meetingRoomUrl={meetingData?.resource}
        meetingStart={meetingData?.start}
        patientId={meetingData?.patientId}
        patientFullName={meetingData?.title}
        patientKaddioId={meetingData?.patientKaddioId}
        patientKaddioContactId={meetingData?.visitId}
        patientEmail={meetingData?.patientEmail}
        patientVisitNumber={meetingData?.patientTotalSessionNumber}
        patientIsSormland={meetingData?.patientIsSormland}
        patientSocialSecurity={meetingData?.patientPnr}
        patientGender={meetingData?.patientGender}
        patientZipCode={meetingData?.patientZipCode}
        sessionId={meetingData?.sessionId}
        chatId={meetingData?.chatId}
        meetingId={meetingData?.id}
      />
      <ReportIssueDialog
        openDialog={openReportIssueDialog}
        onClose={() => setOpenReportIssueDialog(false)}
        meeting={meeting}
      />
      <LeaveMeetingConfirmationDialog
        openDialog={openLeaveMeetingConfirmationDialog}
        onClose={() => {
          setOpenLeaveMeetingConfirmationDialog(false)
        }}
        onConfirm={() => {
          dispatch(fetchCalendarEvents(userId))
          setMeetingRoomData(null)
          setOpenMeetingRoomDialog(false)
          setOpenLeaveMeetingConfirmationDialog(false)
        }}
      />
    </div>
  )
}

const mapStateToProps = ({
  user,
  caregiver,
  calendarEvents,
  alert,
  recommendedAvailabilityStats,
}) => {
  const { userId, employmentStatus: caregiverEmploymentType } = user
  const { minutesUntilBooking: caregiverMinutesUntilBooking } = caregiver

  return {
    userId,
    caregiverMinutesUntilBooking,
    calendarEvents,
    alert,
    recommendedAvailabilityStats,
    caregiverEmploymentType,
  }
}

export default connect(mapStateToProps)(Calendar)
