export const SET_USER = "SET_USER"
export const PUT_USER = "PUT_USER"
export const SET_CAREGIVER = "SET_CAREGIVER"
export const PUT_CAREGIVER = "PUT_CAREGIVER"
export const LOGOUT_USER = "LOGOUT_USER"
export const SET_INITIAL_LOADING = "SET_INITIAL_LOADING"
export const SET_TOKEN = "SET_TOKEN"
export const SET_EXPERTISE = "SET_EXPERTISE"
export const SET_CHATS = "SET_CHATS"
export const SET_PATIENTS = "SET_PATIENTS"
export const SET_ACTIVE_PATIENT = "SET_ACTIVE_PATIENT"
export const POST_MESSAGE = "POST_MESSAGE"
export const ALERT_SHOW = "ALERT_SHOW"
export const ALERT_HIDE = "ALERT_HIDE"
export const SET_INITIAL_MESSAGE = "SET_INITIAL_MESSAGE"
export const SET_USER_AVATAR = "SET_USER_AVATAR"
export const SET_SESSION_JOURNALIZED = "SET_SESSION_JOURNALIZED"
export const SET_SESSIONS = "SET_SESSIONS"
export const SET_CHAT_MESSAGES_REQUEST = "SET_CHAT_MESSAGES_REQUEST"
export const SET_CHAT_MESSAGES_SUCCESS = "SET_CHAT_MESSAGES_SUCCESS"
export const SET_CHAT_MESSAGES_ERROR = "SET_CHAT_MESSAGES_ERROR"
export const SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT =
  "SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT"
export const SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT =
  "SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT"
export const SET_CHAT_MESSAGES_PAGINATION = "SET_CHAT_MESSAGES_PAGINATION"
export const SET_KADDIO_FORMS = "SET_KADDIO_FORMS"
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS"
export const SET_AVAILABLE_TIME_SLOTS = "SET_AVAILABLE_TIME_SLOTS"
export const SET_IKBT_PATIENTS = "SET_IKBT_PATIENTS"
export const SET_RECOMMENDED_CAREGIVER_AVAILABILITY_STATS =
  "SET_RECOMMENDED_CAREGIVER_AVAILABILITY_STATS"
export const UPDATE_IKBT_PATIENT = "UPDATE_IKBT_PATIENT"
