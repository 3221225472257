import { Views, Navigate } from "react-big-calendar"
import { Button, Box } from "@mui/material"
import {
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import {
  addMonths,
  subMonths,
  isSameDay,
  isSameWeek,
  isSameMonth,
  isBefore,
  isAfter,
} from "date-fns"

const fontFamily = () =>
  ["Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    display: "inline-flex !important",
    alignItems: "flex-end !important",
    fontSize: "0.8rem",
    "&:hover, &:focus, &:active, &:active:hover, &:active:focus": {
      backgroundColor: `${theme.palette.primary.main_lightest} !important`,
      borderColor: `${theme.palette.primary.main_lightest} !important`,
      color: `${theme.palette.main} !important`,
    },
    "& svg": {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.375rem 0.375rem",
    },
  },
  addButton: {
    marginLeft: `${theme.spacing(1)} !important`,
    fontSize: "0.8rem",
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.white} !important`,
    "&.ownBookingButton": {
      backgroundColor: `${theme.palette.primary.mint_green_light} !important`,
      borderColor: `${theme.palette.primary.mint_green_light} !important`,
      color: `${theme.palette.primary.main} !important`,
      fontWeight: 600,
    },
    "&.ownBookingButton:hover": {
      backgroundColor: `${theme.palette.primary.mint_green} !important`,
      borderColor: `${theme.palette.primary.mint_green} !important`,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main_light} !important`,
      borderColor: `${theme.palette.primary.main_light} !important`,
    },
    [theme.breakpoints.down(410)]: {
      fontSize: "0.7rem",
      "&:not(.ownBookingButton)": {
        marginLeft: "0 !important",
      },
    },
  },
  "@global": {
    ".rbc-calendar": {
      height: "80vh",
      marginTop: theme.spacing(1),
      fontFamily,
      [theme.breakpoints.down("xl")]: {
        height: "75vh",
      },
      [theme.breakpoints.down("sm")]: {
        height: "85vh",
      },
      "& button": {
        fontFamily,
      },
      "& .rbc-day-slot .rbc-event-label": {
        lineHeight: 1.2,
      },
      "& .rbc-time-content .rbc-timeslot-group": {
        minHeight: "60px",
      },
      "& .rbc-time-header": {
        [theme.breakpoints.down("sm")]: {
          marginRight: "0 !important",
        },
      },
      "& .rbc-events-container": {
        "& .rbc-background-event": {
          borderRadius: 0,
          width: "calc(100% + 10px) !important",
          "&.ownBookingEvent .rbc-event-label": {
            display: "none",
          },
        },
        "& .rbc-event-content": {
          fontSize: "80%",
          lineHeight: 1.2,
        },
        "& .rbc-event": {
          justifyContent: "start",
          width: "calc(100% + 10px) !important",
          "&.reservedBookingEvent": {
            background: "#9AD7F4",
            backgroundImage:
              "repeating-linear-gradient(-45deg, transparent 0, transparent 25%, #AEDFF6 0, #AEDFF6 50%)",
            backgroundSize: "30px 30px",
            border: "1px solid #9AD7F4",
            color: "#000000",
            fontWeight: 500,
          },
        },
      },
      "& .rbc-agenda-view table.rbc-agenda-table tr": {
        backgroundColor: "#FFFFFF !important",
        color: "#373A3C !important",
        border: "1px solid #DDDDDD !important",
      },
      "& .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date-cell": {
        borderRight: "1px solid rgb(221, 221, 221)",
      },
      "& .rbc-agenda-event-cell": {
        cursor: "pointer",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
      // eslint-disable-next-line max-len
      "& div[title^='00:00 - 00:00'], & .rbc-event-continues-earlier[style*='height: 0%'], & .rbc-agenda-time-cell .rbc-continues-after:after":
        {
          display: "none",
        },
    },
    ".rbc-toolbar": {
      fontFamily,
      marginBottom: theme.spacing(3),
      // this breakpoint is specific to react-big-calendar
      [theme.breakpoints.down(1220)]: {
        justifyContent: "space-between",
        rowGap: theme.spacing(1),
      },
      "& .rbc-toolbar-label": {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      "& button.rbc-active": {
        "&, &:hover, &:focus": {
          backgroundColor: `${theme.palette.primary.main} !important`,
          borderColor: `${theme.palette.primary.main} !important`,
          color: `${theme.palette.white} !important`,
        },
      },
      "& .rbc-btn-group:not(:first-of-type)": {
        [theme.breakpoints.up(1220)]: {
          marginRight: theme.spacing(1),
        },
        "& + .MuiBox-root": {
          display: "inline-flex",
          marginLeft: "auto",
          [theme.breakpoints.down(360)]: {
            display: "flex",
            flexDirection: "column",
            rowGap: theme.spacing(1),
          },
        },
        [theme.breakpoints.down(730)]: {
          display: "grid",
          width: "100%",
          marginTop: theme.spacing(1),
          "& button": {
            marginLeft: 0,
            "&:first-child": {
              borderBottomLeftRadius: 0,
              borderTopRightRadius: "4px",
            },
            "&:last-child": {
              borderBottomLeftRadius: "4px",
              borderTopRightRadius: 0,
            },
          },
        },
        [theme.breakpoints.down(410)]: {
          "& button": { fontSize: "0.7rem" },
        },
      },
    },
    ".rbc-month-row": {
      flexBasis: "100px",
      "& .rbc-event-content": {
        fontSize: "70%",
      },
    },
    ".firstVisit": {
      "& td:last-child::after": {
        content: "' (nybesök)'",
        color: "#39ad48",
      },
    },
  },
}))

const CalendarToolbar = ({
  view,
  label,
  date,
  onNavigate,
  onView,
  handleOpenAvailabilityDialog,
}) => {
  const classes = useStyles()

  const getClassName = (viewOption) =>
    view === viewOption
      ? `rbc-active ${classes.toolbarButton}`
      : classes.toolbarButton

  const isNavigationButtonDisabled = (navigationOption) => {
    const lastVisibleDate =
      navigationOption === Navigate.NEXT
        ? addMonths(new Date(), 3)
        : subMonths(new Date(), 3)
    const isCurrentDateBeforeLastVisibleDate = isBefore(date, lastVisibleDate)
    const isCurrentDateAfterLastVisibleDate = isAfter(date, lastVisibleDate)
    switch (view) {
      case Views.DAY:
        return navigationOption === Navigate.NEXT
          ? isSameDay(date, lastVisibleDate) ||
              isCurrentDateAfterLastVisibleDate
          : isSameDay(date, lastVisibleDate) ||
              isCurrentDateBeforeLastVisibleDate
      case Views.WEEK:
      case Views.WORK_WEEK:
        return navigationOption === Navigate.NEXT
          ? isSameWeek(date, lastVisibleDate) ||
              isCurrentDateAfterLastVisibleDate
          : isSameWeek(date, lastVisibleDate) ||
              isCurrentDateBeforeLastVisibleDate
      case Views.MONTH:
      case Views.AGENDA:
        return navigationOption === Navigate.NEXT
          ? isSameMonth(date, lastVisibleDate) ||
              isCurrentDateAfterLastVisibleDate
          : isSameMonth(date, lastVisibleDate) ||
              isCurrentDateBeforeLastVisibleDate
      default:
        return false
    }
  }

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <Button
          className={classes.toolbarButton}
          onClick={() => onNavigate(Navigate.TODAY)}
        >
          Idag
        </Button>
        <Button
          className={classes.toolbarButton}
          disabled={isNavigationButtonDisabled(Navigate.PREVIOUS)}
          onClick={() => onNavigate(Navigate.PREVIOUS)}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          className={classes.toolbarButton}
          disabled={isNavigationButtonDisabled(Navigate.NEXT)}
          onClick={() => onNavigate(Navigate.NEXT)}
        >
          <ArrowForwardIcon />
        </Button>
      </div>
      <div className="rbc-toolbar-label">{label}</div>
      <div className="rbc-btn-group">
        <Button
          className={getClassName(Views.MONTH)}
          onClick={() => onView(Views.MONTH)}
        >
          Månad
        </Button>
        <Button
          className={getClassName(Views.WEEK)}
          onClick={() => onView(Views.WEEK)}
        >
          Vecka
        </Button>
        <Button
          className={getClassName(Views.AGENDA)}
          onClick={() => onView(Views.AGENDA)}
        >
          Dagens möten
        </Button>
      </div>
      <Box>
        <Button
          className={classes.addButton}
          onClick={() => handleOpenAvailabilityDialog(false)}
        >
          Lägg till tillgänglighet
        </Button>
        <Button
          className={`${classes.addButton} ownBookingButton`}
          onClick={() => handleOpenAvailabilityDialog(true)}
        >
          Egen bokning
        </Button>
      </Box>
    </div>
  )
}

export default CalendarToolbar
