import { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Tooltip,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
} from "@mui/material"
import {
  CloseOutlined as ClosedOutlinedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Event as EventIcon,
  PersonOutline as PersonOutlineIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import Select from "components/Select"
import format from "date-fns/format"
import { sv } from "date-fns/locale"
import { issueType as issueTypes } from "utils/issueType"
import { getUserAge } from "utils/getUserAge"
import { formatPersonalNumber } from "utils/formatPersonalNumber"
import { reportSessionIssue } from "api/postReportSessionIssue"
import { useDispatch } from "react-redux"
import { showAlert } from "redux/actions"

const useStyles = makeStyles((theme) => ({
  reportSentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3.75),
    textAlign: "center",
    height: "25rem",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "4.375rem",
    },
  },
  dialogTitle: {
    textAlign: "center",
  },
  closeDialogButton: {
    float: "right",
    color: theme.palette.black,
    minWidth: "fit-content",
    "&:hover": {
      color: theme.palette.dark_gray,
      background: "none",
    },
  },
  sessionInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(3.75),
  },
  sessionText: {
    display: "inline-flex",
    gap: theme.spacing(1.25),
  },
  rowContainer: {
    width: "100%",
    display: "inline-flex",
    alignItems: "start",
    "& .MuiFormControl-root": {
      margin: theme.spacing(0),
      width: "100%",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
    "& .MuiDialogActions-root": {
      marginTop: theme.spacing(1.25),
    },
  },
  radioContainer: {
    display: "flex",
    flexDirection: "column",
  },
  faktureringText: {
    marginLeft: theme.spacing(4),
    marginTop: "-8px",
    color: theme.palette.primary.main,
    fontSize: "0.85rem",
  },
  reportButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
    },
  },
}))

const ReportIssueDialog = ({ openDialog, onClose, meeting }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { start, title, patientId, patientPnr } = meeting || {}
  const { control, handleSubmit, register, watch, reset } = useForm({
    defaultValues: {
      issueType: "",
      visitCompleted: "No",
      supportMessage: "",
    },
  })
  const [modalContent, setModalContent] = useState("form") // "form" | "success"
  const [isSubmitted, setIsSubmitted] = useState(false)

  const selectedIssueType = watch("issueType")
  const supportMessageFilled = watch("supportMessage")
  const requiresSupportMessage =
    selectedIssueType !== issueTypes["Uteblivet besök"] && !supportMessageFilled

  const disableButton =
    !selectedIssueType || requiresSupportMessage || isSubmitted

  useEffect(() => {
    if (openDialog) {
      setModalContent("form")
      setIsSubmitted(false)
    }
  }, [setModalContent, openDialog])

  const onSubmit = async (formData) => {
    if (isSubmitted) return
    const { issueType, visitCompleted, supportMessage } = formData
    setIsSubmitted(true)

    const wasSessionCompleted =
      selectedIssueType === issueTypes["Uteblivet besök"]
        ? false
        : visitCompleted === "Yes"

    return new Promise(() => {
      reportSessionIssue(meeting.id, {
        reason: issueType,
        wasSessionCompleted,
        comment: supportMessage || null,
      })
        .then(() => {
          setModalContent("success")
          reset()
        })
        .catch(() => {
          setIsSubmitted(false)
          dispatch(
            showAlert({
              type: "error",
              message:
                "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se.",
            })
          )
        })
    })
  }

  const onCloseDialog = () => {
    onClose()
    reset()
    setIsSubmitted(false)
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={onCloseDialog}>
      <DialogTitle className={classes.dialogTitle}>
        {modalContent === "form" && "Rapportera händelse"}
        <Button className={classes.closeDialogButton} onClick={onCloseDialog}>
          <ClosedOutlinedIcon />
        </Button>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {modalContent === "success" ? (
          <Box className={classes.reportSentContainer}>
            <Typography variant="h6">Tack!</Typography>
            <Typography>
              Din händelse har rapporterats och kommer att hanteras av
              supporten.
            </Typography>
            <CheckCircleOutlineIcon />
          </Box>
        ) : (
          <>
            <Box className={classes.sessionInfoContainer}>
              {start && (
                <Box
                  className={`${classes.rowContainer} ${classes.sessionText}`}
                >
                  <EventIcon />
                  <Typography className={classes.sessionText}>
                    <Box sx={{ fontWeight: "bold" }} component="span">
                      {format(start, "HH:mm")}
                    </Box>
                    {format(start, "E, d MMMM", { locale: sv })}
                  </Typography>
                </Box>
              )}
              <Box className={`${classes.rowContainer} ${classes.sessionText}`}>
                <PersonOutlineIcon />
                <Box>
                  <Typography className={classes.sessionText}>
                    <Box sx={{ fontWeight: "bold" }} component="span">
                      {title}
                    </Box>{" "}
                    ({patientPnr && getUserAge(patientPnr)} år)
                  </Typography>
                  <Typography>
                    {patientPnr && formatPersonalNumber(patientPnr)} (ID:{" "}
                    {patientId?.slice(0, 5)})
                  </Typography>
                </Box>
              </Box>
            </Box>

            <form
              className={classes.formContainer}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box className={classes.rowContainer}>
                <Select
                  name="issueType"
                  data={Object.entries(issueTypes)}
                  controller={control}
                  label="Typ av händelse"
                  labelId="issue-type"
                  issueTypeSelect
                  fullWidth
                  valueWithTooltip="Övrigt"
                  tooltipText={`Exempelvis icke vårdbesök (sökande för annan person, önskar intyg, ombokning m.m),
                    samt andra avvikelser som supporten bör känna till.`}
                />
              </Box>
              {selectedIssueType && (
                <>
                  {selectedIssueType !== issueTypes["Uteblivet besök"] && (
                    <Box>
                      <Tooltip
                        arrow
                        title="Vårdbesök innebär att bedömning eller åtgärd har genomförts."
                        placement="bottom"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 100 }}
                      >
                        <Typography>
                          Kunde ett vårdbesök genomföras trots händelsen?
                        </Typography>
                      </Tooltip>
                      <Controller
                        name="visitCompleted"
                        control={control}
                        defaultValue="No"
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <Box className={classes.radioContainer}>
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="Nej"
                              />
                              {field.value === "No" && (
                                <Typography className={classes.faktureringText}>
                                  Fakturering i Kaddio ignoreras automatiskt
                                </Typography>
                              )}
                            </Box>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Ja"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography sx={{ fontWeight: 600 }}>
                      Meddelande till supporten{" "}
                      {selectedIssueType === issueTypes["Uteblivet besök"] && (
                        <Typography component="span" sx={{ fontWeight: 400 }}>
                          (frivilligt)
                        </Typography>
                      )}
                    </Typography>
                    <Box mb={1.25} />
                    <TextField
                      {...register("supportMessage")}
                      control={control}
                      multiline
                      fullWidth
                      rows={6}
                      placeholder={
                        (selectedIssueType === issueTypes["Tekniska problem"]
                          ? "Beskriv det tekniska problemet"
                          : selectedIssueType === issueTypes["Övrigt"] &&
                            "Beskriv händelsen") || ""
                      }
                    />
                  </Box>
                  {selectedIssueType !== issueTypes["Övrigt"] && (
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {selectedIssueType === issueTypes["Uteblivet besök"] && (
                        <Typography component="span">
                          Fakturering i Kaddio ignoreras automatiskt.{" "}
                        </Typography>
                      )}
                      Kontakta patienten och påminn om att boka en ny tid.
                    </Typography>
                  )}
                </>
              )}
              <DialogActions>
                {selectedIssueType && disableButton ? (
                  <Tooltip
                    arrow
                    title="Fyll i meddelande till supporten för att slutföra."
                    placement="bottom"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 100 }}
                  >
                    <Box>
                      <Button
                        disabled={disableButton}
                        className={classes.reportButton}
                        type="submit"
                        variant="contained"
                      >
                        Rapportera
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Box>
                    <Button
                      disabled={disableButton}
                      className={classes.reportButton}
                      type="submit"
                      variant="contained"
                    >
                      Rapportera
                    </Button>
                  </Box>
                )}
              </DialogActions>
            </form>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ReportIssueDialog
