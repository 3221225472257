import React from "react"
import { Avatar } from "@mui/material"
import { makeStyles } from "@mui/styles"
import PersonIcon from "@mui/icons-material/Person"
import classNames from "classnames"

import { chatTypes } from "pages/chat/components/ChatCard"

const useStyles = makeStyles((theme) => {
  const colors = {
    [chatTypes.active]: {
      backgroundColor: theme.palette.primary.main_light,
      color: theme.palette.light_gray,
    },
    [chatTypes.read]: {
      backgroundColor: theme.palette.gray,
      color: theme.palette.dark_gray,
    },
    [chatTypes.unread]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.light_gray,
    },
    default: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }

  return {
    avatar: ({ size, type }) => ({
      boxSizing: "border-box",
      backgroundColor: colors[type]?.backgroundColor,
      color: colors[type]?.color,
      width: size,
      height: size,
      borderWidth: 1.5,
      borderColor: theme.palette.lightest_gray,
      textTransform: "uppercase",
      fontSize: `calc(${size}px / 2.875)`,
      fontWeight: theme.typography.fontWeightBold,
      "& svg": {
        width: (size * 3) / 5,
        height: (size * 3) / 5,
      },
    }),
  }
})

const createInitials = (str) => {
  const [firstName = "", ...rest] = str.match(/\b(\w)/g) || []
  const tmp = [...rest]
  const fullName = [firstName]

  if (tmp.length) {
    const [lastName] = tmp.reverse()
    fullName.push(lastName)
  }
  const initials = fullName.join("")
  return initials
}

const DefaultAvatar = ({ size = 40, src = "", name, type = "default" }) => {
  const classes = useStyles({ size, type })

  return (
    <Avatar src={src} className={classNames(classes.avatar)}>
      {name ? createInitials(name) : <PersonIcon />}
    </Avatar>
  )
}

export default DefaultAvatar
