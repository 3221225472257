import { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import config from "config"
import Axios from "axios"
import { CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { fetchUnreadMessagesCount } from "redux/actions"
import Cookie from "js-cookie"

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const AuthRoute = ({ loading, user, children, ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      dispatch(fetchUnreadMessagesCount())
    }
  }, [user])

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      // any valid IP address (when testing on mobile phones)
      window.location.hostname.match(
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi
      )
  )

  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress size={50} color="primary" />
      </div>
    )
  }

  if (user) {
    return children
  }

  if (isLocalhost === false) {
    const { origin } = window.location
    let redirectionError = false
    let channelId = ""
    let errorMessage = ""
    if (origin.includes("test")) {
      if (!config.redirect_url.includes("test")) {
        redirectionError = true
        channelId = config.slackErrorsTestChannelId
        errorMessage = `Wrong CAREGIVER REDIRECTION URL ${config.redirect_url} used in Test environment!`
      }
    } else if (config.redirect_url.includes("test")) {
      redirectionError = true
      channelId = config.slackErrorsProdChannelId
      errorMessage = `Wrong CAREGIVER REDIRECTION URL ${config.redirect_url} used in Production environment!`
    }
    if (redirectionError) {
      const apiUrl = origin.replace("caregiver", "api")
      Axios.post(
        `${apiUrl}/slack/sendMessage`,
        {
          channelId,
          message: errorMessage,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
    }
  }

  Cookie.remove("caregiver", { domain: config.cookie_path })
  sessionStorage.removeItem("caregiver")
  window.location.replace(config.redirect_url)
  return (
    <div className={classes.root}>
      <CircularProgress size={50} color="primary" />
    </div>
  )
}

const mapStateToProps = ({ loading, user }) => ({ loading, user })

export default connect(mapStateToProps)(AuthRoute)
