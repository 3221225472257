import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  Check as CheckIcon,
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  SummarizeOutlined as SummarizeOutlinedIcon,
  TrendingDown as TrendingDownIcon,
  TrendingUp as TrendingUpIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material"
import format from "date-fns/format"
import { sv } from "date-fns/locale"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import { fetchMeetingInfo } from "../redux/actions"

const useStyles = makeStyles((theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(4),
  },
  chip: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5, 0.5),
    display: "flex",
    width: "fit-content",
    fontWeight: 500,
    height: "22px",
    fontSize: "12px",
  },
  chipSuccess: {
    backgroundColor: theme.palette.primary.mint_green_light,
    borderColor: theme.palette.primary.mint_green_light,
  },
  chipWarning: {
    backgroundColor: "#FFF3EB",
    color: "#FF6B07",
    borderColor: "#FFF3EB",
  },
  chipUteblevSenast: {
    color: "#FF6B07",
    borderColor: "#C7D0CA",
  },
  rowContainer: {
    display: "flex",
    "& .MuiFormControl-root": {
      marginLeft: theme.spacing(1.25),
      width: "230px",
    },
    "& svg": {
      marginRight: theme.spacing(1.25),
      marginTop: theme.spacing(0.25),
    },
    "& p": {
      marginTop: theme.spacing(0.125),
    },
  },
  rowContainerLabel: {
    fontWeight: 500,
    fontSize: "14px",
    marginRight: "5px",
  },
  rowContainerValue: {
    fontSize: "14px",
  },
  collapseButton: {
    display: "flex",
    padding: theme.spacing(1, 0),
    textTransform: "none",
    gap: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  sessionSummaryLabel: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "100%",
    letterSpacing: "0px",
  },
  patientSummary: {
    backgroundColor: "#FFF6E8",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
  },
  patientSummaryIcon: {
    fontSize: "20px",
  },
}))

const MeetingCollapsableInfo = ({ meeting, openDialog }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  useEffect(() => {
    setIsCollapseOpen(false)
    if (
      meeting &&
      openDialog &&
      // if sessionSummary = undefined that means that data for that meeting was not yet loaded
      meeting.sessionSummary === undefined
    ) {
      setIsLoading(true)
      dispatch(fetchMeetingInfo(meeting?.id)).finally(() => setIsLoading(false))
    }
  }, [openDialog, dispatch, meeting])

  const renderFormAnswer = (name, start, end, startPoints, endPoints) => {
    if (!start && !end) {
      return null
    }

    if (start === end) {
      return (
        <Tooltip
          title={`Skattning 1: ${start}`}
          placement="top"
          PopperProps={{
            sx: {
              ".MuiTooltip-tooltip": {
                bgcolor: "#322F35",
              },
            },
          }}
        >
          <Box display="flex">
            <Typography className={classes.rowContainerLabel}>
              {name}:
            </Typography>
            <Typography className={classes.rowContainerValue}>
              {endPoints}
            </Typography>
          </Box>
        </Tooltip>
      )
    }

    if (startPoints === endPoints) {
      return (
        <Tooltip
          title={
            <Box>
              Skattning 1: {start}
              <br />
              Skattning 2: {end}
            </Box>
          }
          placement="top"
          PopperProps={{
            sx: {
              ".MuiTooltip-tooltip": {
                bgcolor: "#322F35",
              },
            },
          }}
        >
          <Box display="flex">
            <Typography className={classes.rowContainerLabel}>
              {name}:
            </Typography>
            <Typography className={classes.rowContainerValue}>
              {endPoints}
            </Typography>
          </Box>
        </Tooltip>
      )
    }

    let icon = null
    if (endPoints > startPoints) {
      icon = (
        <TrendingUpIcon
          className={[classes.patientSummaryIcon, classes.chipWarning]}
          sx={{
            marginLeft: "2px",
          }}
        />
      )
    } else if (endPoints < startPoints) {
      icon = (
        <TrendingDownIcon
          className={classes.patientSummaryIcon}
          sx={{
            marginLeft: "2px",
          }}
        />
      )
    }

    let tooltip = null
    if (icon) {
      tooltip = (
        <Tooltip
          title={
            <Box>
              Skattning 1: {start}: {startPoints} p
              <br />
              Skattning 2: {end}: {endPoints} p
            </Box>
          }
          placement="top"
          PopperProps={{
            sx: {
              ".MuiTooltip-tooltip": {
                bgcolor: "#322F35",
              },
            },
          }}
        >
          {icon}
        </Tooltip>
      )
    }

    return (
      <Box display="flex">
        <Typography className={classes.rowContainerLabel}>{name}:</Typography>
        <Typography className={classes.rowContainerValue}>
          {endPoints}
        </Typography>
        {tooltip}
      </Box>
    )
  }

  return (
    <Box>
      {isLoading && (
        <Box className={classes.loader}>
          <CircularProgress size={35} color="primary" />
        </Box>
      )}
      {meeting?.sessionSummary && (
        <Box my={1}>
          <Box display="flex" gap={1} ml="28px">
            <Chip
              icon={
                meeting?.ikbtPatient ? (
                  <CheckIcon sx={{ fontSize: "18px" }} />
                ) : (
                  <CloseIcon sx={{ fontSize: "18px" }} />
                )
              }
              variant="outlined"
              color="primary"
              label={meeting?.ikbtPatient ? "IKBT" : "Ej i IKBT"}
              className={`${classes.chip} ${
                meeting?.ikbtPatient ? classes.chipSuccess : classes.chipWarning
              }`}
            />
            {Number(meeting?.patientTotalSessionNumber) === 1 && (
              <Chip
                variant="outlined"
                color="primary"
                label="Nybesök"
                className={`${classes.chip}`}
                sx={{
                  borderColor: "#EEEEEE",
                }}
              />
            )}
            {meeting?.isMissedLastMeeting && (
              <Chip
                variant="outlined"
                color="warning"
                label="Uteblev senast"
                className={`${classes.chip} ${classes.chipUteblevSenast}`}
              />
            )}
          </Box>
          <Button
            className={classes.collapseButton}
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            aria-expanded={isCollapseOpen}
            color="default"
          >
            <SummarizeOutlinedIcon
              sx={{ fontSize: "18px", color: "#081413" }}
            />
            <Typography fontSize={14} fontWeight="medium">
              Journalsammanfattning
            </Typography>
            {isCollapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Collapse in={isCollapseOpen}>
            <Box className={classes.patientSummary}>
              {!!meeting?.previousCaregiverSessionNum && (
                <Box className={classes.rowContainer}>
                  <Typography className={classes.rowContainerLabel}>
                    Tidigare kontakt:
                  </Typography>
                  <Typography className={classes.rowContainerValue}>
                    {meeting?.previousCaregiverSessionNum} sessioner
                  </Typography>
                </Box>
              )}
              {meeting?.suicideRisk && (
                <Box className={classes.rowContainer}>
                  <Typography className={classes.rowContainerLabel}>
                    Suicidrisk:
                  </Typography>
                  <Typography
                    className={classes.rowContainerValue}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Måttlig/hög
                    <Tooltip
                      title="Bör följas upp baserat på tidigare bedömning."
                      placement="top"
                      PopperProps={{
                        sx: {
                          ".MuiTooltip-tooltip": {
                            bgcolor: "#322F35",
                          },
                        },
                      }}
                    >
                      <WarningAmberIcon
                        className={[
                          classes.chipWarning,
                          classes.patientSummaryIcon,
                        ]}
                      />
                    </Tooltip>
                  </Typography>
                </Box>
              )}

              {meeting?.diagnoses && meeting?.diagnoses.length > 0 && (
                <Box className={classes.rowContainer}>
                  <>
                    <Typography className={classes.rowContainerLabel}>
                      Diagnos:
                    </Typography>
                    <Typography className={classes.rowContainerValue}>
                      {meeting?.diagnoses.map((d) => (
                        <Typography key={d.id} fontSize={14}>
                          {d.name}
                        </Typography>
                      ))}
                    </Typography>
                  </>
                </Box>
              )}

              <Box className={classes.rowContainer} sx={{ gap: 1 }}>
                {meeting?.formAnswers && (
                  <>
                    {meeting?.formAnswers.endGad7Points !== null
                      ? renderFormAnswer(
                          "GAD-7",
                          meeting?.formAnswers.startGad7CompletedAt,
                          meeting?.formAnswers.endGad7CompletedAt,
                          meeting?.formAnswers.startGad7Points,
                          meeting?.formAnswers.endGad7Points
                        )
                      : null}
                    {meeting?.formAnswers.endPhq9Points !== null
                      ? renderFormAnswer(
                          "PHQ-9",
                          meeting?.formAnswers.startPhq9CompletedAt,
                          meeting?.formAnswers.endPhq9CompletedAt,
                          meeting?.formAnswers.startPhq9Points,
                          meeting?.formAnswers.endPhq9Points
                        )
                      : null}
                  </>
                )}
              </Box>
              {/* eslint-disable-next-line no-nested-ternary */}
              {meeting?.ikbtPatient ? (
                <>
                  <Box className={classes.rowContainer} mt={2}>
                    <Box display="flex">
                      <Typography className={classes.rowContainerLabel}>
                        IKBT-program:
                      </Typography>
                      <Typography className={classes.rowContainerValue}>
                        {meeting?.ikbtPatient.programName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Box display="flex">
                      <Typography className={classes.rowContainerLabel}>
                        Journalförd modul:
                      </Typography>
                      <Typography className={classes.rowContainerValue}>
                        {meeting?.ikbtPatient.latestSessionModuleNumber}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography className={classes.rowContainerLabel}>
                        Pågående modul:
                      </Typography>
                      <Typography className={classes.rowContainerValue}>
                        {meeting?.ikbtPatient.startedModuleNumber}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box mt={1}>
                  {meeting?.homework && (
                    <Box className={classes.rowContainer}>
                      <Typography
                        className={classes.rowContainerLabel}
                        minWidth={162}
                      >
                        Hemuppgift:
                      </Typography>
                      <Typography className={classes.rowContainerValue}>
                        {meeting?.homework}
                      </Typography>
                    </Box>
                  )}
                  {meeting?.addedMaterials && (
                    <Box className={classes.rowContainer}>
                      <Typography
                        className={classes.rowContainerLabel}
                        minWidth={162}
                      >
                        Tilldelat material:
                      </Typography>
                      <Typography className={classes.rowContainerValue}>
                        {meeting?.addedMaterials}
                      </Typography>
                    </Box>
                  )}
                  {meeting?.treatmentPlan && (
                    <Box className={classes.rowContainer}>
                      <Typography
                        className={classes.rowContainerLabel}
                        minWidth={162}
                      >
                        Behandlingsplanering:
                      </Typography>
                      <Typography className={classes.rowContainerValue}>
                        {meeting?.treatmentPlan}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box my={1}>
              {meeting?.treatmentSummary && (
                <Box py={2}>
                  <Typography className={classes.sessionSummaryLabel}>
                    Alla sessioner
                  </Typography>
                  <Typography fontSize={14} mt="5px" lineHeight="1.25">
                    {meeting?.treatmentSummary}
                  </Typography>
                </Box>
              )}
              {meeting?.sessionSummary && (
                <Box>
                  <Box className={classes.rowContainer} alignItems="center">
                    <Typography className={classes.sessionSummaryLabel}>
                      {meeting?.start > new Date() ? "Föregående" : "Aktuell"}{" "}
                      session{" "}
                    </Typography>
                    <Typography fontSize={14} ml={1}>
                      {format(
                        meeting?.lastSessionDate || meeting?.start,
                        "yyyy-MM-dd",
                        { locale: sv }
                      )}
                    </Typography>
                  </Box>
                  {meeting.sessionSummary.map((field) => (
                    <Box key={field.name} className={classes.rowContainer}>
                      <Typography fontSize={14} minWidth={120}>
                        {field.name}:
                      </Typography>
                      <Typography fontSize={14} lineHeight="1.25">
                        {field.summary}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Collapse>
        </Box>
      )}
    </Box>
  )
}

export default MeetingCollapsableInfo
