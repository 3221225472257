import {
  SET_USER,
  PUT_USER,
  SET_CAREGIVER,
  PUT_CAREGIVER,
  LOGOUT_USER,
  SET_INITIAL_LOADING,
  SET_TOKEN,
  SET_EXPERTISE,
  SET_CHATS,
  SET_PATIENTS,
  POST_MESSAGE,
  ALERT_SHOW,
  ALERT_HIDE,
  SET_INITIAL_MESSAGE,
  SET_USER_AVATAR,
  SET_SESSION_JOURNALIZED,
  SET_SESSIONS,
  SET_CHAT_MESSAGES_REQUEST,
  SET_CHAT_MESSAGES_SUCCESS,
  SET_CHAT_MESSAGES_ERROR,
  SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT,
  SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT,
  SET_CHAT_MESSAGES_PAGINATION,
  SET_KADDIO_FORMS,
  SET_CALENDAR_EVENTS,
  SET_AVAILABLE_TIME_SLOTS,
  SET_IKBT_PATIENTS,
  UPDATE_IKBT_PATIENT,
  SET_RECOMMENDED_CAREGIVER_AVAILABILITY_STATS,
} from "redux/actionTypes"
import { defaultPagination } from "utils"

const INITIAL_STATE = {
  alert: null,
  loading: true,
  payment: null,
  caregiver: null,
  user: null,
  token: null,
  expertise: [],
  chats: {
    loading: true,
    inactiveInstancesLoading: false,
    error: false,
    activeInstances: [],
    inactiveInstances: [],
    activeUnreadMessagesCount: 0,
    inactiveUnreadMessagesCount: 0,
    messages: [],
    messagesCount: 0,
    messagesLoading: false,
    messagesPagination: defaultPagination,
    activeChat: null,
    switchChatLoading: false,
  },
  patients: [],
  sessions: {
    loading: true,
    error: false,
    active: [],
    past: [],
  },
}

// eslint-disable-next-line default-param-last
function rootReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALERT_SHOW:
      return { ...state, alert: action.payload }
    case ALERT_HIDE:
      return { ...state, alert: { ...state.alert, ...action.payload } }
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case SET_INITIAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      }
    case PUT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    case SET_CAREGIVER:
      return {
        ...state,
        caregiver: action.payload,
      }
    case PUT_CAREGIVER:
      return {
        ...state,
        caregiver: {
          ...state.caregiver,
          ...action.payload,
        },
      }
    case LOGOUT_USER:
      return {
        ...INITIAL_STATE,
        loading: false,
      }
    case SET_EXPERTISE:
      return {
        ...state,
        expertise: action.payload,
      }
    case SET_CHATS:
      return {
        ...state,
        chats: {
          ...state.chats,
          ...action.payload,
        },
      }
    case SET_PATIENTS:
      return {
        ...state,
        patients: action.payload,
      }
    case POST_MESSAGE:
      return {
        ...state,
        chats: {
          ...state.chats,
          messages: [...state.chats.messages, action.payload],
        },
      }
    case SET_INITIAL_MESSAGE:
      return {
        ...state,
        chats: {
          ...state.chats,
          ...action.payload,
        },
      }
    case SET_USER_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatarUrl: action.payload,
        },
      }
    case SET_SESSION_JOURNALIZED:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          past: action.payload,
        },
      }
    case SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      }
    case SET_CHAT_MESSAGES_REQUEST:
      return {
        ...state,
        chats: {
          ...state.chats,
          messagesLoading: true,
        },
      }
    case SET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        chats: {
          ...state.chats,
          messages: [...action.payload.data, ...state.chats.messages],
          messagesCount: action.payload.totalCount,
          messagesLoading: false,
        },
      }
    case SET_CHAT_MESSAGES_ERROR:
      return {
        ...state,
        chats: {
          ...state.chats,
          messagesLoading: false,
        },
      }
    case SET_CHAT_ACTIVE_UNREAD_MESSAGES_COUNT: {
      return {
        ...state,
        chats: {
          ...state.chats,
          activeUnreadMessagesCount: action.payload,
        },
      }
    }
    case SET_CHAT_INACTIVE_UNREAD_MESSAGES_COUNT: {
      return {
        ...state,
        chats: {
          ...state.chats,
          inactiveUnreadMessagesCount: action.payload,
        },
      }
    }
    case SET_CHAT_MESSAGES_PAGINATION:
      return {
        ...state,
        chats: {
          ...state.chats,
          messagesPagination: action.payload,
        },
      }
    case SET_KADDIO_FORMS:
      return {
        ...state,
        kaddioForms: action.payload,
      }
    case SET_CALENDAR_EVENTS:
      return {
        ...state,
        calendarEvents: action.payload,
      }
    case SET_AVAILABLE_TIME_SLOTS:
      return {
        ...state,
        availableTimeSlots: action.payload,
      }
    case SET_IKBT_PATIENTS:
      return {
        ...state,
        ikbtPatients: action.payload,
      }
    case SET_RECOMMENDED_CAREGIVER_AVAILABILITY_STATS:
      return {
        ...state,
        recommendedAvailabilityStats: action.payload,
      }
    case UPDATE_IKBT_PATIENT:
      return {
        ...state,
        ikbtPatients: state.ikbtPatients.map((patient) =>
          patient.patientId === action.payload.patientId
            ? { ...patient, ...action.payload.update }
            : patient
        ),
      }
    default:
      return state
  }
}

export default rootReducer
