import React, { useEffect, createRef } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
import IdleTimer from "react-idle-timer"
import { Provider } from "react-redux"
import store from "redux/store"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "theme"
import Header from "components/Header"
import LocalStorageListenerWrapper from "components/LocalStorageListenerWrapper"
import Profile from "pages/profile"
import Sessions from "pages/sessions"
import Chat from "pages/chat"
import Calendar from "pages/calendar"
import IkbtOverview from "pages/ikbt-overview"
import { getCaregiverFromCookieOrSessionStorage } from "utils/getCaregiverFromCookieOrSessionStorage"
import Alert from "components/Alert"
import {
  removeUserCredentials,
  setIncomingMessage,
  setLastMessageSeen,
  setSessionJournalized,
  handlePatientMeetingEvent,
  handleMeetingReservedByPatient,
  handleMeetingIgnored,
} from "redux/actions"
import io from "socket.io-client"
import config from "config"
import posthog from "posthog-js"
import AuthRoute from "./AuthRoute"
import withClearCache from "../clearCache"

// 60 minutes
const IDLE_TIMER = 1000 * 60 * 60
const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route
          path="/messages"
          element={
            <AuthRoute>
              <Header>
                <Chat />
              </Header>
            </AuthRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthRoute>
              <Header>
                <Profile />
              </Header>
            </AuthRoute>
          }
        />
        <Route
          path="/sessions"
          element={
            <AuthRoute>
              <Header>
                <Sessions />
              </Header>
            </AuthRoute>
          }
        />
        <Route
          path="/ikbt-overview"
          element={
            <AuthRoute>
              <Header>
                <IkbtOverview />
              </Header>
            </AuthRoute>
          }
        />
        <Route
          path="/"
          element={
            <AuthRoute>
              <Header>
                <Calendar />
              </Header>
            </AuthRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  )
}

function MainApp() {
  const idleTimerRef = createRef()

  useEffect(() => {
    const caregiver = getCaregiverFromCookieOrSessionStorage(store.dispatch)
    if (caregiver) {
      const { id: userId, token } = caregiver
      posthog.identify(userId)
      const socket = io(`${config.ws_url}?token=${token}`)

      socket.on("connect", () => {
        socket.emit("ready for data", {})
      })
      socket.on("update", ({ message }) => {
        store.dispatch(setIncomingMessage(message))
      })
      socket.on("messageSeen", (data) => {
        store.dispatch(setLastMessageSeen(data))
      })
      socket.on("contact: signedStatusChanged", (data) => {
        const { sessionId, isSigned } = data
        store.dispatch(setSessionJournalized(sessionId, isSigned))
      })
      socket.on("meeting: booked", (data) => {
        const eventType = "meeting: booked"
        store.dispatch(handlePatientMeetingEvent(data, eventType))
      })
      socket.on("meeting: canceled", (data) => {
        const eventType = "meeting: canceled"
        store.dispatch(handlePatientMeetingEvent(data, eventType))
      })
      socket.on("meeting: reserved", (data) => {
        store.dispatch(handleMeetingReservedByPatient(data))
      })
      socket.on("meeting: ignored", (data) => {
        store.dispatch(handleMeetingIgnored(data))
      })
      return () => {
        socket.disconnect()
      }
    }
  }, [])

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalStorageListenerWrapper>
            <AppRoutes />
          </LocalStorageListenerWrapper>
          <IdleTimer
            ref={idleTimerRef}
            element={document}
            onIdle={() => store.dispatch(removeUserCredentials())}
            timeout={IDLE_TIMER}
          />
          <Alert />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
}

export default App
