import { makeStyles } from "@mui/styles"
import { Box, Button, Tooltip, Fade } from "@mui/material"
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from "@mui/icons-material"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionButton: {
    padding: theme.spacing(0.5, 1),
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main_light,
      "& svg": {
        color: theme.palette.primary.main_light,
      },
    },
  },
}))

const MeetingEvent = ({ handleOpenReportIssueDialog, ...props }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <span>{props.event.title}</span>
      {props.event.visitId && (
        <Tooltip
          arrow
          title="Rapportera uteblivet besök, tekniska problem och övriga händelser (ex icke vårdbesök) till supporten."
          placement="bottom"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 100 }}
        >
          <Button
            disableRipple
            startIcon={<ErrorOutlineOutlinedIcon color="primary" />}
            onClick={(event) => handleOpenReportIssueDialog(event, props.event)}
            className={classes.actionButton}
          >
            Rapportera händelse
          </Button>
        </Tooltip>
      )}
    </Box>
  )
}

export default MeetingEvent
