import { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  CircularProgress,
  Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import MaterialConfirmationDialog from "./MaterialConfirmationDialog"

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: "10px",
    boxShadow: "none",
    position: "relative",
  },
  iframe: {
    minHeight: "50vh",
    display: "block",
    width: "100%",
  },
  sendButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
      color: theme.palette.white,
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  previewContent: {
    padding: "8px 4px",
    overflow: "hidden",
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
    "@media only screen and (max-width: 690px)": {
      ".MuiDialog-paperWidthMd": {
        width: "97%",
        margin: "0",
      },
    },
  },
}))

const MaterialPreviewDialog = ({ onClose, dialogItem, openDialog, onSend }) => {
  const [openMaterialConfirmationDialog, setOpenMaterialConfirmationDialog] =
    useState(false)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (openDialog) {
      setIframeLoaded(false)
    }
  }, [openDialog])

  const handleSendMaterial = () => {
    onSend()
    setOpenMaterialConfirmationDialog(false)
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={openDialog} onClose={onClose}>
        <DialogTitle>{dialogItem.description}</DialogTitle>
        <DialogContent className={classes.previewContent}>
          <Card className={classes.card}>
            <>
              <iframe
                title="Materials"
                src={`${dialogItem.url}#gdpr-accept-cookies`}
                onLoad={() => setIframeLoaded(true)}
                className={classes.iframe}
              />
              {!iframeLoaded && (
                <Box className={classes.loader}>
                  <CircularProgress size={50} color="primary" />
                </Box>
              )}
            </>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            className={classes.closeButton}
            color="default"
          >
            Stäng
          </Button>
          <Button
            onClick={() => setOpenMaterialConfirmationDialog(true)}
            className={classes.sendButton}
          >
            Skicka
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialConfirmationDialog
        openDialog={openMaterialConfirmationDialog}
        onClose={() => setOpenMaterialConfirmationDialog(false)}
        onSend={handleSendMaterial}
      />
    </>
  )
}

export default MaterialPreviewDialog
