import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { Button, Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { Link } from "react-router-dom"
import isEmpty from "lodash.isempty"
import {
  fetchExpertise,
  updateForm,
  removeUserCredentials,
} from "redux/actions"
import profileValidation from "validation/profileValidation"
// import Checkbox from "../../../components/Checkbox"
import Input from "../../../components/Input"
import DividerText from "../../../components/DividerText"
import { TouchClick } from "../../../components/TouchButton"
import MultiSelect from "../../../components/MultiSelect"

const useStyles = makeStyles((theme) => ({
  formInputContainer: {
    [theme.breakpoints.up("sm")]: {
      "& div": {
        marginRight: theme.spacing(4),
      },
      "& div:last-child": {
        marginRight: 0,
      },
    },
  },
  formInputs: {
    paddingBottom: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 250,
    },
  },
  formSelect: {
    marginBottom: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 250,
    },
  },
  formSections: {
    paddingBottom: theme.spacing(4),
  },
  formButtonsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  formButtons: {
    width: 120,
    height: 50,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
    "&[type='submit']:hover": {
      backgroundColor: theme.palette.primary.main_light,
    },
  },
  formButton: {
    height: 50,
  },
}))

const resolver = (data) => {
  const { firstName, lastName, ...form } = data
  const { error, value } = profileValidation({ ...form })
  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    errors: responseErrors,
    values: responseValues,
  }
}

const Form = ({ dispatch, user, caregiver, expertise }) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    activeInMatchmaking,
    maxPatients,
  } = user

  const { shortDesc, longDesc, expertise: caregiverExpertise } = caregiver
  const classes = useStyles()
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isValidating },
  } = useForm({
    resolver,
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      shortDesc: shortDesc || "",
      longDesc: longDesc || "",
      expertise: caregiverExpertise || [],
      email: email || "",
      phoneNumber: phoneNumber || "",
      maxPatients,
      activeInMatchmaking,
    },
  })

  useEffect(() => {
    dispatch(fetchExpertise())
  }, [dispatch])

  const onSubmit = (data) => {
    dispatch(updateForm(data))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.formInputContainer}>
        <Input
          className={classes.formInputs}
          name="firstName"
          label="Förnamn"
          disabled
          control={control}
          errors={errors}
        />
        <Input
          className={classes.formInputs}
          name="lastName"
          label="Efternamn"
          disabled
          control={control}
          errors={errors}
        />
      </Box>

      <DividerText className={classes.formSections} text="Beskrivning" />
      <Box className={classes.formInputContainer}>
        <Input
          className={classes.formInputs}
          name="shortDesc"
          label="Kort beskrivning"
          multiline
          control={control}
          errors={errors}
        />
        <Input
          className={classes.formInputs}
          name="longDesc"
          label="Lång beskrivning"
          multiline
          control={control}
          errors={errors}
        />
      </Box>

      <DividerText className={classes.formSections} text="Expertis" />
      <MultiSelect
        name="expertise"
        data={expertise}
        control={control}
        className={classes.formSelect}
        errors={errors}
      />

      <DividerText className={classes.formSections} text="Kontaktuppgifter" />
      <Box className={classes.formInputContainer}>
        <Input
          className={classes.formInputs}
          name="email"
          label="Email"
          control={control}
          errors={errors}
        />
        <Input
          className={classes.formInputs}
          name="phoneNumber"
          label="Telefon"
          type="tel"
          control={control}
          errors={errors}
        />
      </Box>

      {/* <DividerText sx={{ paddingBottom: 2 }} text="Inställningar" />
      <Input
        className={classes.formInputs}
        name="maxPatients"
        label="Max antal patienter"
        type="number"
        control={control}
        errors={errors}
      />
      <Box>
        <Checkbox
          control={control}
          name="activeInMatchmaking"
          label="Aktiv i matchning"
        />
      </Box> */}

      <DividerText className={classes.formSections} text="Ledighet" />
      <Box className={classes.formSections}>
        <Button
          className={classes.formButton}
          onClick={() =>
            window.open("https://forms.gle/dY5aNrzL77gNcdSm6", "_blank")
          }
          variant="outlined"
        >
          Rapportera ledighet
        </Button>
      </Box>

      <DividerText className={classes.formSections} text="Konto" />
      <Box className={classes.formSections}>
        <TouchClick
          display="block"
          onClick={() => {
            dispatch(removeUserCredentials())
          }}
        >
          <ExitToAppIcon color="primary" />
          <Box ml={1}>
            <Typography color="primary">Logga ut</Typography>
          </Box>
        </TouchClick>
      </Box>

      <Box className={classes.formButtonsContainer}>
        <Button
          className={classes.formButtons}
          variant="outlined"
          component={Link}
          to="/"
          color="default"
        >
          Avbryt
        </Button>
        <Button
          disabled={isEmpty(dirtyFields) || isValidating}
          className={classes.formButtons}
          variant="contained"
          type="submit"
        >
          Spara
        </Button>
      </Box>
    </form>
  )
}

const mapStateToProps = ({ user, caregiver, expertise }) => ({
  user,
  caregiver,
  expertise,
})

export default connect(mapStateToProps)(Form)
