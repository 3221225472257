import { useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { PersonOutline as PersonOutlineIcon } from "@mui/icons-material"
import format from "date-fns/format"
import { sv } from "date-fns/locale"
import isBefore from "date-fns/isBefore"
import addHours from "date-fns/addHours"
import config from "config"
import { getUserAge } from "utils/getUserAge"
import { cancelMeeting } from "api/cancelMeeting"
import { fetchCalendarEvents, setActiveChatId, showAlert } from "redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { formatPersonalNumber } from "utils/formatPersonalNumber"
import { useNavigate } from "react-router-dom"
import CancelMeetingConfirmationDialog from "./CancelMeetingConfirmationDialog"
import MeetingCollapsableInfo from "./MeetingCollapsableInfo"

const useStyles = makeStyles((theme) => ({
  joinButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
    },
  },
  closeButton: {
    backgroundColor: "#eeeeee",
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  dialogTitle: {
    paddingTop: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "column",
    },
  },
  dialogContent: {
    padding: theme.spacing(1, 2.5),
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
    "& .MuiFormControl-root": {
      marginLeft: theme.spacing(1.25),
      width: "230px",
    },
    "& svg": {
      marginRight: theme.spacing(1.25),
      marginTop: theme.spacing(0.25),
    },
    "& p": {
      marginTop: theme.spacing(0.125),
    },
  },
  link: {
    "&, & p": {
      color: theme.palette.primary.main,
      textDecorationColor: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: 14,
    },
  },
  cancelMeetingButton: {
    color: theme.palette.error.main,
    padding: theme.spacing(0, 0.5),
    float: "right",
    "& svg": {
      fontSize: "1rem",
      marginRight: theme.spacing(0.5),
    },
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
    "@media only screen and (max-width: 690px)": {
      ".MuiDialog-paperWidthMd": {
        width: "97%",
        margin: "0",
      },
    },
  },
}))

const MeetingDialog = ({
  onClose,
  openDialog,
  meetingId,
  userId,
  onJoinMeeting,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const meeting = useSelector((state) =>
    state.calendarEvents?.bookings?.find((b) => b.id === meetingId)
  )

  const isPastMeeting = isBefore(addHours(meeting?.start, 5), new Date())
  const [
    openCancelMeetingConfirmationDialog,
    setOpenCancelMeetingConfirmationDialog,
  ] = useState(false)
  const [meetingCancellationError, setMeetingCancellationError] = useState(null)

  const handleMeetingCancellation = async (deleteAvailability) => {
    try {
      await cancelMeeting(meetingId, deleteAvailability)
      dispatch(fetchCalendarEvents(userId))
      setOpenCancelMeetingConfirmationDialog(false)
      onClose()
      dispatch(
        showAlert({
          type: "success",
          message: "Videosamtalet har avbokats och patienten har informerats.",
        })
      )
    } catch (error) {
      setMeetingCancellationError(
        "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
      )
    }
  }

  const handleOpenChat = async () => {
    await dispatch(setActiveChatId(meeting?.chatId))
    onClose()
    if (window.location.pathname !== "/messages") {
      navigate("/messages")
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Button
          className={classes.cancelMeetingButton}
          onClick={() => {
            setOpenCancelMeetingConfirmationDialog(true)
          }}
        >
          Avboka
        </Button>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography component="h1" fontWeight="medium" fontSize={20}>
          {meeting?.title}
        </Typography>
        <Box display="flex" mt="5px">
          <Typography sx={{ fontWeight: "medium" }} fontSize={14}>
            {meeting?.start &&
              format(meeting.start, "HH:mm EEEE, d MMMM", { locale: sv })}
          </Typography>
          <Typography ml={1} fontSize={14}>
            {meeting?.costReceiver === "ignore"
              ? "Ignorerad"
              : `Session ${meeting?.patientTotalSessionNumber}`}
          </Typography>
        </Box>
        <Box mt={2.5} display="flex">
          <PersonOutlineIcon sx={{ fontSize: "18px" }} />
          <Box ml="10px">
            <Typography fontSize={14}>
              {meeting?.patientPnr && formatPersonalNumber(meeting?.patientPnr)}
              , {meeting?.patientPnr && getUserAge(meeting?.patientPnr)} år
            </Typography>
            <Typography fontSize={14} mt="5px">
              ID {meeting?.patientId && meeting.patientId.slice(0, 5)}
            </Typography>
          </Box>
        </Box>
        <MeetingCollapsableInfo meeting={meeting} openDialog={openDialog} />
        <Box className={classes.rowContainer} mt="30px">
          {meeting?.visitId ? (
            <Link
              href={`${config.kaddioUrl}/contacts/${meeting?.visitId}`}
              underline="always"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <Typography>Journalanteckning</Typography>
            </Link>
          ) : (
            <Typography fontSize={14}>
              Journalanteckning skapas i Kaddio 1h innan mötet
            </Typography>
          )}
        </Box>
        <Box className={classes.rowContainer} mt={2}>
          <Link
            component="button"
            underline="always"
            className={classes.link}
            onClick={() => handleOpenChat()}
          >
            <Typography>Meddelanden</Typography>
          </Link>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: "20px", gap: 0.25 }}>
        <Button
          onClick={() => onClose()}
          className={classes.closeButton}
          color="default"
        >
          Stäng
        </Button>
        <Button
          disabled={isPastMeeting}
          className={classes.joinButton}
          onClick={() => onJoinMeeting(meeting)}
          variant="contained"
        >
          Anslut
        </Button>
      </DialogActions>
      <CancelMeetingConfirmationDialog
        openDialog={openCancelMeetingConfirmationDialog}
        onClose={() => {
          setOpenCancelMeetingConfirmationDialog(false)
          setMeetingCancellationError(null)
        }}
        onMeetingCancellation={handleMeetingCancellation}
        error={meetingCancellationError}
      />
    </Dialog>
  )
}

export default MeetingDialog
