import React from "react"
import { Box, Typography, Badge } from "@mui/material"
import { makeStyles } from "@mui/styles"
import classNames from "classnames"
import DefaultAvatar from "components/DefaultAvatar"
import AnonymousModeWrapper from "components/AnonymousModeWrapper"
import SessionCountIconWithTooltip from "components/SessionCountIconWithTooltip"
import { differenceInDays, format } from "date-fns"
import isEmpty from "lodash.isempty"
import { containsHtml } from "utils/containsHtml"

export const chatTypes = {
  active: "active",
  read: "read",
  unread: "unread",
}

const useStyles = makeStyles((theme) => {
  const chatBackgroundColors = {
    [chatTypes.active]: {
      default: theme.palette.primary.main,
      hover: theme.palette.primary.main_light,
    },
    [chatTypes.read]: {
      default: theme.palette.light_gray,
      hover: theme.palette.gray,
    },
    [chatTypes.unread]: {
      default: theme.palette.primary.main_lightest,
      hover: theme.palette.primary.main_lighter,
    },
  }

  return {
    wrapper: {
      boxSizing: "border-box",
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(4),
      "&:last-of-type": {
        paddingBottom: theme.spacing(2),
      },
    },
    customBadge: ({ type }) => ({
      width: 12,
      height: 12,
      borderRadius: "50%",
      right: theme.spacing(1),
      top: `calc(50% + ${theme.spacing(1)})`,
      transform: "translateY(-50%)",
      backgroundColor: chatBackgroundColors[type]?.hover,
    }),
    root: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      border: `1px solid ${theme.palette.gray}`,
      borderRadius: 6,
      padding: theme.spacing(1),
      cursor: "pointer",
    },
    chatBox: ({ type }) => ({
      backgroundColor: chatBackgroundColors[type]?.default,
      "&:hover": {
        backgroundColor: chatBackgroundColors[type]?.hover,
        "& .MuiTypography-root": {
          color: theme.palette.white,
        },
      },
      "& .MuiTypography-root": {
        color:
          type === chatTypes.active ? theme.palette.white : theme.palette.black,
      },
    }),
    textContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minWidth: 0,
      marginLeft: theme.spacing(2),
      "& > p": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    nameAndTime: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > p:first-child": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& > p:last-child": {
        whiteSpace: "nowrap",
        paddingLeft: theme.spacing(1),
      },
    },
    text: {
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      color: theme.palette.black,
      fontWeight: theme.typography.fontWeightRegular,
      "&.bold": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }
})

const isTimestampToday = (timestamp) => {
  const today = new Date()
  const createdAt = new Date(timestamp)

  const dayDiff = differenceInDays(today, createdAt)

  if (dayDiff) return format(createdAt, "d MMM")

  return format(createdAt, "HH:mm")
}

const removeHTMLTags = (html) =>
  html ? html.replace(/<\/?[^>]+(>|$)/g, "") : html

const ChatCard = ({ instance, activeChat, sessionCount, onClick }) => {
  const {
    chatId,
    lastMessage,
    lastMessageDate,
    fromUserId,
    seen,
    systemEntry,
    notify,
    unmatched,
    userId,
    userFullName,
    userAvatar,
  } = instance || {}
  const instanceType =
    // eslint-disable-next-line no-nested-ternary
    chatId === activeChat?.chatId
      ? chatTypes.active
      : !unmatched && fromUserId === userId && (!systemEntry || notify) && !seen
      ? chatTypes.unread
      : chatTypes.read
  const classes = useStyles({ type: instanceType })
  const checkUnreadChats = () => chatTypes.unread === instanceType

  if (!userId) {
    return null
  }

  return (
    <Badge
      invisible={!checkUnreadChats()}
      variant="dot"
      className={classes.wrapper}
      classes={{ badge: classes.customBadge }}
    >
      <Box
        className={classNames(classes.root, classes.chatBox)}
        onClick={() => onClick()}
      >
        <AnonymousModeWrapper>
          <DefaultAvatar
            size={46}
            src={userAvatar}
            type={instanceType}
            name={userFullName}
          />
        </AnonymousModeWrapper>
        <Box className={classes.textContainer}>
          <Box className={classes.nameAndTime}>
            <Typography className={classNames(classes.text, "bold")}>
              <AnonymousModeWrapper>
                {userFullName}
                {sessionCount > 9 && (
                  <SessionCountIconWithTooltip sessionCount={sessionCount} />
                )}
              </AnonymousModeWrapper>
            </Typography>
            <Typography className={classNames(classes.text, "bold")}>
              {!isEmpty(lastMessageDate)
                ? isTimestampToday(lastMessageDate)
                : null}
            </Typography>
          </Box>
          <Typography
            className={classNames(classes.text, { bold: checkUnreadChats() })}
          >
            <AnonymousModeWrapper>
              {containsHtml(lastMessage)
                ? removeHTMLTags(lastMessage)
                : lastMessage}
            </AnonymousModeWrapper>
          </Typography>
        </Box>
      </Box>
    </Badge>
  )
}

export default ChatCard
