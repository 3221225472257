import { Box, Typography } from "@mui/material"
import { useState, useEffect } from "react"

const WeeklyAvailabilityStats = ({ numberOfWeeklyAvailabilities, stats }) => {
  const [recommendedAmount, setRecommendedAmount] = useState(0)
  const [missingAmount, setMissingAmount] = useState(0)

  useEffect(() => {
    if (stats?.recommendedAvailabilities) {
      setRecommendedAmount(Math.ceil(stats.recommendedAvailabilities))
    }
    if (stats?.matchAvailability) {
      setMissingAmount(Math.ceil(-stats.matchAvailability))
    }
  }, [stats])

  return (
    <Box mx={2} mt={3}>
      <Typography color="primary">
        Upplagda tider: {numberOfWeeklyAvailabilities || 0}
        {stats?.workloadPercentage && (
          <>
            {" "}
            av {recommendedAmount}
            {stats.matchAvailability < 0 && <> | Saknas: {missingAmount}</>}
          </>
        )}
      </Typography>
    </Box>
  )
}

export default WeeklyAvailabilityStats
