/* eslint-disable no-nested-ternary */
import { useEffect, useState, useMemo, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setActiveChat, showAlert } from "redux/actions"
import { getChatEntries } from "api/getChatEntries"
import { isSameDay } from "date-fns"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { entryType } from "utils/entryType"
import TitleDivider from "pages/chat/components/TitleDivider"
import ChatBubble from "pages/chat/components/ChatBubble"

const useStyles = makeStyles((theme) => ({
  title: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  content: {
    padding: theme.spacing(1, 1, 2.5),
    overflow: "auto",
    minHeight: "200px",
  },
  actionButtons: {
    borderTop: `2px solid ${theme.palette.gray}`,
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
  },
  openEntireChatButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
      color: theme.palette.white,
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none",
    },
  },
  noContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    minHeight: "200px",
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const previewMessagesPagination = {
  skip: 0,
  limit: 10,
}

const ChatPreviewDialog = ({
  dispatch,
  onClose,
  open,
  session,
  activeInstances,
  inactiveInstances,
  activeInstancesLoading,
  inactiveInstancesLoading,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const [chatMessages, setChatMessages] = useState([])
  const [chatMessagesLoading, setChatMessagesLoading] = useState(true)
  const [error, setError] = useState(null)
  const [chatInstance, setChatInstance] = useState(null)
  const chatInstances = useMemo(
    () => [...activeInstances, ...inactiveInstances],
    [activeInstances, inactiveInstances]
  )

  const resetAllData = () => {
    setChatMessagesLoading(true)
    setChatMessages([])
    setError(null)
  }

  useEffect(() => {
    if (session?.chatId) {
      resetAllData()
      const getChatMessages = async () => {
        try {
          const {
            data: { data: chatEntries },
          } = await getChatEntries(session.chatId, previewMessagesPagination)
          setChatMessages(
            chatEntries.filter(
              (entry) => entry.type !== entryType.SYSTEM_PATIENT
            )
          )
          setChatMessagesLoading(false)
        } catch (error) {
          setError("Något gick fel, försök igen.")
          setChatMessagesLoading(false)
        }
      }
      getChatMessages()
    }
  }, [session?.chatId])

  useEffect(() => {
    if (
      !activeInstancesLoading &&
      !inactiveInstancesLoading &&
      session?.chatId
    ) {
      setChatInstance(
        chatInstances?.find((instance) => instance.chatId === session.chatId)
      )
    }
  }, [
    activeInstancesLoading,
    inactiveInstancesLoading,
    session?.chatId,
    chatInstances,
  ])

  const openEntireChat = async () => {
    if (chatInstance) {
      await dispatch(setActiveChat(chatInstance))
      onClose()
      navigate("/messages")
    } else {
      dispatch(
        showAlert({
          type: "info",
          message:
            "Chatten visas ej längre då patienten inte varit aktiv på 3 månader. Kontakta supporten.",
        })
      )
    }
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        {session?.patient}
        <br />- Senaste {chatMessages?.length} meddelande
        {chatMessages?.length !== 1 && "n"} -
      </DialogTitle>
      {activeInstancesLoading ||
      inactiveInstancesLoading ||
      chatMessagesLoading ? (
        <Box className={classes.noContent}>
          <CircularProgress size={50} color="primary" />
        </Box>
      ) : error ? (
        <Box className={classes.noContent}>
          <Typography component="span" className={classes.error}>
            {error}
          </Typography>
        </Box>
      ) : (
        <DialogContent className={classes.content}>
          {chatMessages.map((message, index) => {
            const { id, createdAt } = message
            const isSameDayMessage =
              index > 0 &&
              isSameDay(
                new Date(chatMessages[index - 1]?.createdAt),
                new Date(createdAt)
              )

            return (
              <Fragment key={`${id}_${createdAt}`}>
                {!isSameDayMessage && <TitleDivider timeStamp={createdAt} />}
                <ChatBubble
                  key={id}
                  activeChat={chatInstance}
                  caregiverId={session?.caregiverId}
                  message={message}
                />
              </Fragment>
            )
          })}
        </DialogContent>
      )}
      <DialogActions className={classes.actionButtons}>
        <Button onClick={onClose} className={classes.closeButton}>
          Stäng
        </Button>
        <Button
          onClick={() => openEntireChat()}
          className={classes.openEntireChatButton}
          disabled={activeInstancesLoading || inactiveInstancesLoading}
        >
          Se alla meddelanden
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ chats }) => {
  const {
    activeInstances,
    inactiveInstances,
    loading: activeInstancesLoading,
    inactiveInstancesLoading,
  } = chats
  return {
    activeInstances,
    inactiveInstances,
    activeInstancesLoading,
    inactiveInstancesLoading,
  }
}

export default connect(mapStateToProps)(ChatPreviewDialog)
