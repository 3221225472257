import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { fetchAvailableTimeSlots } from "redux/actions"
import { createAvailability } from "api/createAvailability"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  format,
  addMonths,
  addDays,
  isDate,
  isWithinInterval,
  addMinutes,
  parse,
} from "date-fns"
import DatePicker from "components/DatePicker"
import Select from "components/Select"
import addTimeSlotValidationSchema from "validation/addTimeSlotValidation"
import { getTimeSlotsBetweenTimesWithDefinedInterval } from "utils/getTimeSlotsBetweenTimesWithDefinedInterval"
import { availabilityType } from "utils/availabilityType"
import { availabilityVisibilityType } from "utils/availabilityVisibilityType"
import { generateTimeSlots } from "utils/generateTimeSlots"

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: "inline-flex",
    alignItems: "start",
    width: "70%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(0, 1),
      "&, & .MuiOutlinedInput-root": {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    "& .MuiInputLabel-root": {
      minWidth: "55px",
    },
    "& .MuiTypography-root": {
      margin: theme.spacing(3, 1),
    },
  },
  actions: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  bookButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
      color: theme.palette.white,
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: `${theme.spacing(1)} !important`,
  },
}))

const timeFormat = "HH:mm"
const dateFormat = "MMM d yyyy"
const timeIncrementInterval = 15
const startTimeSlots = getTimeSlotsBetweenTimesWithDefinedInterval(
  "00:00",
  "23:15",
  timeIncrementInterval
)

const resolver = (data) => {
  data.availabilityStartTime = data.availabilityStartTime
    ? parse(data.availabilityStartTime, timeFormat, new Date())
    : null
  const { error, value } = addTimeSlotValidationSchema(data)
  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const AddTimeSlot = ({
  userId,
  bookings,
  onClose,
  availableTimeSlots,
  meetingStart,
  setSelectedTimeSlot,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const [isTimeSelectDisabled, setIsTimeSelectDisabled] = useState(true)
  const [timeSelectData, setTimeSelectData] = useState(startTimeSlots)
  const [error, setError] = useState(null)
  const defaultValues = {
    availabilityDate: null,
    availabilityStartTime: "",
  }
  const { control, handleSubmit, watch, resetField, reset, formState } =
    useForm({
      resolver,
      defaultValues,
    })
  const { isSubmitting } = formState
  const selectedAvailabilityDate = watch("availabilityDate")

  const isInvalidSelectedDate = (selectedDate) =>
    !isDate(selectedDate) ||
    !isWithinInterval(selectedDate, {
      start: new Date(),
      end: addMonths(new Date(), 3),
    })

  const getAvailableStartTimeSlots = (slots) => {
    const availabilityDate = format(selectedAvailabilityDate, dateFormat)
    const availableTimeSlotsOnAvailabilityDate = availableTimeSlots
      .filter((slot) => slot.dayDate === availabilityDate)
      .flatMap((availability) =>
        availability.slots.map((slot) => {
          const slotStartTime = new Date(slot.start)
          const slotStart = format(slotStartTime, timeFormat)
          const slotStartMinusTimeIncrementInterval = format(
            addMinutes(slotStartTime, -timeIncrementInterval),
            timeFormat
          )
          const slotStartPlusTimeIncrementInterval = format(
            addMinutes(slotStartTime, timeIncrementInterval),
            timeFormat
          )

          return [
            slotStartMinusTimeIncrementInterval,
            slotStart,
            slotStartPlusTimeIncrementInterval,
          ]
        })
      )
    const bookingsOnAvailabilityDate = bookings
      .filter((slot) => format(slot.start, dateFormat) === availabilityDate)
      .map((booking) => {
        const bookingStartTime = new Date(booking.start)
        const bookingSlotStart = format(bookingStartTime, timeFormat)
        const bookingStartMinusTimeIncrementInterval = format(
          addMinutes(bookingStartTime, -timeIncrementInterval),
          timeFormat
        )
        const bookingStartPlusTimeIncrementInterval = format(
          addMinutes(bookingStartTime, timeIncrementInterval),
          timeFormat
        )

        return [
          bookingStartMinusTimeIncrementInterval,
          bookingSlotStart,
          bookingStartPlusTimeIncrementInterval,
        ]
      })

    return slots.filter(
      (value) =>
        !availableTimeSlotsOnAvailabilityDate.flat().includes(value) &&
        !bookingsOnAvailabilityDate.flat().includes(value)
    )
  }

  useEffect(() => {
    if (isInvalidSelectedDate(selectedAvailabilityDate)) {
      resetField("availabilityStartTime")
      setIsTimeSelectDisabled(true)
      setTimeSelectData(startTimeSlots)
    } else {
      setIsTimeSelectDisabled(false)
      setTimeSelectData(getAvailableStartTimeSlots(startTimeSlots))
    }
  }, [selectedAvailabilityDate])

  const handleErrorMessage = (error) => {
    const errorMessage = error?.response?.data || "Något gick fel"
    setError(errorMessage)
  }

  const onSubmit = (formData) => {
    const { availabilityDate, availabilityStartTime } = formData
    const timeSlotStart = new Date(
      new Date(availabilityDate.getTime()).setHours(
        availabilityStartTime.getHours(),
        availabilityStartTime.getMinutes(),
        availabilityStartTime.getSeconds()
      )
    )
    const timeSlotEnd = addMinutes(timeSlotStart, 30)
    const start = timeSlotStart
    const end = addMinutes(start, 30)

    return new Promise(() => {
      createAvailability(userId, {
        start,
        end,
        type: availabilityType.Aldrig,
        visibilityType: availabilityVisibilityType.Återbesök,
        transform: true,
        timeZone,
      })
        .then(() => {
          setSelectedTimeSlot({
            start: timeSlotStart,
            end: timeSlotEnd,
            timeZone,
          })
          setError(null)
          dispatch(fetchAvailableTimeSlots(meetingStart))
          reset()
        })
        .catch((error) => {
          handleErrorMessage(error)
          reset({
            availabilityDate,
            availabilityStartTime: format(availabilityStartTime, timeFormat),
          })
        })
    })
  }

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.rowContainer}>
          <DatePicker
            name="availabilityDate"
            controller={control}
            label="Datum"
            minDate={addDays(new Date(), 1)}
            maxDate={addMonths(new Date(), 3)}
          />
        </Box>
        <Box mt={3} />
        <Box className={`${classes.rowContainer} ${classes.timeContainer}`}>
          <Select
            name="availabilityStartTime"
            controller={control}
            label="Starttid"
            data={timeSelectData}
            disabled={isTimeSelectDisabled}
          />
        </Box>
        <Box className={classes.rowContainer}>
          {error && (
            <Typography
              variant="caption"
              component="p"
              className={classes.errorMessage}
            >
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Stäng
        </Button>
        <Button
          type="submit"
          className={classes.bookButton}
          variant="contained"
          disabled={isTimeSelectDisabled || isSubmitting}
        >
          Boka
        </Button>
      </DialogActions>
    </form>
  )
}

function mapStateToProps({ user, calendarEvents }) {
  const { userId } = user
  const meetings = calendarEvents.bookings?.filter(
    (meeting) => meeting.start > new Date()
  )
  const ownBookings = calendarEvents.available?.filter(
    (availability) =>
      availability.visibilityType === availabilityVisibilityType.OwnBooking &&
      availability.end > new Date()
  )
  const ownBookingSlots = ownBookings.flatMap((ownBooking) => {
    const { start, end } = ownBooking
    return generateTimeSlots(start, end)
  })

  return { userId, bookings: [...meetings, ...ownBookingSlots] }
}

export default connect(mapStateToProps)(AddTimeSlot)
