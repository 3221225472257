import { createTheme, alpha } from "@mui/material/styles"
import { grey } from "@mui/material/colors"

const basicTheme = createTheme({
  palette: {
    primary: {
      main: "#1F514A",
      mint_green: "#E2F8E9",
      mint_green_light: "#EAF7EC",
      main_light: "#35625B",
      main_lighter: "#C7D0CA",
      main_lightest: "#E5EAE6",
      lighter: "rgba(200, 226, 228, 1)",
    },
    secondary: {
      light: "rgba(215, 203, 215, 1)",
      main: "rgba(133, 75, 133, 1)",
    },
    dark_gray: "rgba(130, 130, 130, 1)",
    gray: "rgba(216, 216, 216, 1)",
    light_gray: "rgba(238, 238, 238, 1)",
    lighter_gray: "rgba(247, 247, 247, 1)",
    lightest_gray: "rgba(251, 251, 251, 1)",
    black: "rgba(000, 000, 000, 1)",
    white: "rgba(255, 255, 255, 1)",
    default: {
      main: grey[300],
      dark: grey[400],
    },
    warning: {
      main: "rgba(235, 165, 72, 1)",
      dark: "rgba(255, 116, 23, 1)",
      darker: "rgb(237,85,59)",
    },
    error: {
      main: "rgba(223, 52, 52, 1)",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

const theme = createTheme(basicTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "default" },
          style: {
            color: basicTheme.palette.getContrastText(
              basicTheme.palette.grey[300]
            ),
          },
        },
        {
          props: { variant: "outlined", color: "default" },
          style: {
            color: basicTheme.palette.text.primary,
            borderColor:
              basicTheme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${basicTheme.palette.action.disabledBackground}`,
            },
            "&:hover": {
              borderColor:
                basicTheme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                basicTheme.palette.text.primary,
                basicTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
        {
          props: { color: "default", variant: "text" },
          style: {
            color: basicTheme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                basicTheme.palette.text.primary,
                basicTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
  },
})

export default theme
